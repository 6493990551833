package pages.duoSettings.duoProfile.ui.partners

import csstype.Display
import csstype.FlexDirection
import csstype.px
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 20.px
}
