package utils

import kotlinx.browser.document
import kotlinx.browser.window
import online.interactiver.common.duo.courseprogress.PlatformInfo
import kotlin.js.RegExp

fun getPlatformInfo(): PlatformInfo {
    return PlatformInfo(
        browserName = window.navigator.appName,
        browserVersion = window.navigator.appVersion,
        platform = window.navigator.platform,
        platformLanguage = window.navigator.language,
        screenWidth = window.screen.width,
        screenHeight = window.screen.height,
        isInStandalone = isInStandaloneMode()
    )
}


fun isIos(): Boolean {
    return listOf(
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod"
    ).contains(window.navigator.platform)
            || (window.navigator.userAgent.contains("Mac") && document.asDynamic().ontouchend)
}

fun isInStandaloneMode(): Boolean = window.navigator.asDynamic().standalone;
