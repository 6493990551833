package online.interactiver.common.utils

import online.interactiver.common.admin.interactive.phrase.Phrase
import online.interactiver.common.export.ExportData
import online.interactiver.common.interactivepicture.*
import kotlin.random.Random
import online.interactiver.common.interactivepicture.Phrase as PhraseElement

fun InteractiveElement.getSpecificDefaultScore(data: ExportData): Int {

    val type = this.type

    return when (type) {
        "Puzzle" -> (data.gapsCount ?: 0) / 2
        "Selector" -> this.selector?.options?.size?.let { it - 1 } ?: 0
        "Button" -> 2
        "Input" -> 3
        "Phrase" -> phrase?.words?.size ?: 0
        else -> 0
    }

}

fun InteractiveElement.export(data: ExportData): InteractiveElement {
    return copy(
        identifier = identifier.clone(),
        visibleElement = visibleElement.clone(),
        hint = hint.clone(),
        gapPuzzle = gapPuzzle?.clone(),
        input = input?.clone(),
        button = button?.clone(),
        selector = selector?.clone(),
        scores = scores?.map { it.export(this.getSpecificDefaultScore(data)) }?.toMutableList(),
        phrase = phrase?.export(data)
    )
}

fun PhraseElement.export(data: ExportData): PhraseElement {
    return copy(
        leftTop = leftTop.clone(),
        settings = settings.export(data)
    )
}

fun PhraseSettings.export(data: ExportData): PhraseSettings {
    return copy(
        inputs = inputs.map { it.export(data) }.toMutableList(),
        selectors = selectors.map { it.export(data) }.toMutableList(),
        puzzles = puzzles.map { it.export(ExportData(this.puzzles.size)) }.toMutableList()
    )
}

fun InteractivePicture.export(data: ExportData): InteractivePicture {
    return copy(
        identifier = identifier.clone(),
        background = background.clone(),
        style = style.clone(),
        frames = frames?.map { it.export(data) }?.toMutableList(),
        figuresAndLines = figuresAndLines?.map { it.export(data) }?.toMutableList(),
        controls = controls?.map { it.export(data) }?.toMutableList(),
        gapPuzzles = gapPuzzles?.map { it.export(data) }?.toMutableList(),
        others = others?.map { it.export(data) }?.toMutableList(),
    )
}

fun InteractivePicture.getSyncedInteractivePicture(phrases: Set<Phrase>, newSyncedElementId: String? = null): InteractivePicture {
    val newInteractivePicture = this.clone()

    val syncList = f@{ list: MutableList<InteractiveElement>? ->
        if (list == null) {
            return@f null
        }
        for (i in 0 until list.size) {
            val text = list[i].visibleElement.text?.simpleText
            if (list[i].isSynced != true || text == null || list[i].identifier.id == newSyncedElementId) {
                continue
            }

            val phrase = phrases.find { it.phrase == text } ?: continue
            list[i] = list[i].copy(
                hint = list[i].hint.copy(
                    text = list[i].hint.text?.copy(
                        simpleText = phrase.textDescription
                    )
                )
            )
            if (list[i].visibleElement.type != "MarkerStatic") {
                list[i] = list[i].copy(
                    sound = list[i].sound?.copy(
                        src = phrase.soundDescription,
                        filename = phrase.soundDescription
                    )
                )
            }
        }

        list
    }

    val syncSelectorOption = f@{ list: MutableList<SelectorOption>? ->
        if (list == null) {
            return@f null
        }

        for (i in 0 until list.size) {
            list[i].figuresAndLines = syncList(list[i].figuresAndLines)
        }

        list
    }

    val syncControls = f@{ list: MutableList<InteractiveElement>? ->
        val controls = syncList(list) ?: return@f null

        for (i in 0 until controls.size) {
            if (controls[i].visibleElement.type == "SelectorControl") {
                controls[i] = controls[i].copy(
                    selector = controls[i].selector?.copy(
                        options = syncSelectorOption(controls[i].selector?.options?.clone())
                    )
                )
            }
        }

        controls
    }

    newInteractivePicture.figuresAndLines = syncList(newInteractivePicture.figuresAndLines)
    newInteractivePicture.controls = syncControls(newInteractivePicture.controls)
    newInteractivePicture.gapPuzzles = syncList(newInteractivePicture.gapPuzzles)

    return newInteractivePicture
}

fun <T : Copyable<T>> MutableList<T>.clone(): MutableList<T> {
    return this.map { it.clone() }.toMutableList()
}

inline fun <reified T : Copyable<T>> Array<T>.clone(): Array<T> {
    return this.map { it.clone() }.toTypedArray()
}

const val PHRASE_CUSTOM_SEPARATOR = '|'
fun String.splitToPhraseWords(): MutableList<PhraseWord> {
    val useCustomSeparator = this.any { it == PHRASE_CUSTOM_SEPARATOR }
    return this.trim().split(
        if (useCustomSeparator) PHRASE_CUSTOM_SEPARATOR else ' '
    ).filter { it.isNotBlank() }.map { PhraseWord(it.trim()) }.toMutableList()
}

fun MutableList<String>.shuffleAndTakeWithRequiredElement(random: Random, requiredElement: String, numberOfElementsToTake: Int): List<String> {
    return (this.filter { it != requiredElement }
        .shuffled(random)
        .take(numberOfElementsToTake - 1) + requiredElement)
        .shuffled()
}