package entities.pwaInstall

import Modal
import csstype.px
import enums.ELocalStorageKeys
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.js.jso
import online.interactiver.common.enums.ELanguage
import org.w3c.dom.events.Event
import react.*
import shared.components.checkboxWithLabel.CheckboxWithLabel
import utils.isInStandaloneMode
import utils.isIos

external interface PwaInstallModalProps : Props {
    var nativeLanguage: String
    var pwaInstallEvent: Event?
    var onChange: (Event?) -> Unit
}

val PwaInstallModal = FC<PwaInstallModalProps> { props ->
    val (isOpen, setIsOpen) = useState(false)
    val wasShownFlagForSamsung = useRef(false)

    useEffectOnce {
        if (!isIos()) {
            return@useEffectOnce
        }

        setIsOpen(true)
    }

    useEffectOnce {
        val handleBeforeInstallPrompt = f@{ event: Event ->
            event.preventDefault()
            if (wasShownFlagForSamsung.current == true) return@f
            props.onChange(event)
            setIsOpen(true)
            wasShownFlagForSamsung.current = true
        }

        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt)

        cleanup {
            window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt)
        }
    }

    val userAskedToHide = localStorage.getItem(ELocalStorageKeys.HIDE_INSTALL_PWA_POPUP.key)?.toBooleanStrictOrNull()
    val (checkedHidePopup, setCheckedHidePopup) = useState(userAskedToHide ?: false)

    if (isInStandaloneMode()) {
        return@FC
    }

    Modal {
        open = isOpen && userAskedToHide != true
        onCancel = {
            setIsOpen(false)
        }
        afterClose = {
            localStorage.setItem(ELocalStorageKeys.HIDE_INSTALL_PWA_POPUP.key, checkedHidePopup.toString())
        }
        style = jso {
            maxWidth = 605.px
        }
        width = "80vw"
        footer = null
        bodyStyle = jso(modal)

        if (isIos()) {
            IosContent {
                this.nativeLanguage = props.nativeLanguage
            }
        } else {
            ChromeBasedContent {
                this.nativeLanguage = props.nativeLanguage
                this.pwaInstallEvent = props.pwaInstallEvent
                onChange = {
                    props.onChange(it)
                    setIsOpen(false)
                }
            }
        }
        CheckboxWithLabel {
            label = getHideModalCheckbox(props.nativeLanguage)
            checked = checkedHidePopup
            onChange = {
                setCheckedHidePopup(it)
            }
        }
    }
}

fun getHideModalCheckbox(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Больше не показывать"
} else {
    "Don't show anymore"
}
