package pages.duoSettings.ui

import antd.Button
import csstype.Auto
import csstype.Margin
import csstype.px
import emotion.react.css
import entities.pwaInstall.PwaInstallEvent
import features.upgradeToProButton.UpgradeToProButton
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.statistics.logger.AttemptStatus
import online.interactiver.common.statistics.logger.AttemptsLog
import pages.duoSettings.ui.installButtonWithModal.InstallButtonWithModal
import pages.languageAuto.ui.ERoute
import pages.studentDuo.selectIsBasicTariffPlan
import pages.studentDuo.selectPwaInstallEvent
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import react.FC
import react.VFC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.router.useNavigate
import shared.components.ProtectedRoute.ProtectedRoute
import utils.isInStandaloneMode
import utils.isIos
import utils.localization.Localization
import utils.sendLogToServer
import utils.types.extend
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoLayout.StudentDuoLayout
import kotlin.js.Promise

val DuoSettings = VFC {
    val navigate = useNavigate()
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val isBasicTariffPlan = useSelector(selectIsBasicTariffPlan)
    val pwaInstallEvent = useSelector(selectPwaInstallEvent)

    ProtectedRoute {
        toStudentDuo = true

        StudentDuoLayout {
            buttons = FC {
                Button {
                    css(newTaskButton.extend {
                        margin = Margin(0.px, Auto.auto)
                    })
                    onClick = {
                        navigate(-1)
                    }
                    +Localization.getBackButtonText(nativeLanguage)
                }
            }
            div {
                css(buttonsContainer)
                if (isBasicTariffPlan) {
                    UpgradeToProButton {
                        link = ERoute.PAYMENT.path
                        language = nativeLanguage
                    }
                }
                Button {
                    css(newTaskButton)
                    onClick = {
                        navigate("/duo/profile-settings")
                    }
                    +getProfile(nativeLanguage)
                }
                Button {
                    css(newTaskButton)
                    onClick = {
                        navigate("/duo/exercises-settings")
                    }
                    +getExercisesSettings(nativeLanguage)
                }
                Button {
                    css(newTaskButton)
                    onClick = {
                        navigate("/duo/manage-notifications")
                    }
                    +getManageNotifications(nativeLanguage)
                }
                if (pwaInstallEvent != null) {
                    Button {
                        css(generateButton)
                        onClick = {
                            (pwaInstallEvent.asDynamic().prompt() as Promise<PwaInstallEvent>).then {
                                if (it.outcome != "accepted") return@then

                                GlobalScope.launch {
                                    sendLogToServer(
                                        AttemptsLog(
                                            null,
                                            null, null,
                                            AttemptStatus.INSTALL_PWA,
                                            window.navigator.userAgent, null
                                        )
                                    )
                                }
                                console.log(it)
                            }.catch {
                                console.error(it)
                            }
                        }
                        +getInstallPwa(nativeLanguage)
                    }
                }
                if (isIos() && !isInStandaloneMode()) {
                    InstallButtonWithModal {}
                }
            }
        }
    }
}

fun getInstallPwa(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Установить приложение"
} else {
    "Install application"
}

fun getProfile(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Профиль"
    } else {
        "Profile"
    }
}

fun getExercisesSettings(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Настройки заданий"
    } else {
        "Exercises Settings"
    }
}

fun getManageNotifications(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Настройки уведомлений"
    } else {
        "Manage notifications"
    }
}