package widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew

import antd.Button
import app.useAppDispatch
import emotion.react.css
import features.ShareButton.ui.ShareButton
import kotlinx.browser.localStorage
import online.interactiver.common.autogeneration.LanguageAutoState
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.enums.ELanguageLevel
import org.w3c.dom.get
import pages.constructor.ui.components.editors.designEditor.activeFilter
import pages.languageAuto.UpdateCurrentLanguageAutoRequest
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import shared.components.Icon
import shared.components.description.Description
import shared.components.header.Header
import widgets.ExerciseSettings.selectUserSliderSettings

external interface ShareOrCreateNewProps : Props {
    var link: String
    var requestId: Int
    var embedCode: String
    var slider: String
}

val ShareOrCreateNew = FC<ShareOrCreateNewProps> { props ->

    val userSliderSettings = useSelector(selectUserSliderSettings)

    val dispatch = useAppDispatch()

    div {
        css(nextStepContainer)
        div {
            css(nextStepTextContainer)
            div {
                css(nextStepHeaderContainer)
                Header {
                    text = "4. We saved your exercise in your gallery"
                }
                Icon {
                    src = "ic_party popper_35x35.svg"
                }
            }
            Description {
                fontSize = 16.0
                lineHeight = 21.6
                +"What is your next step?"
            }
        }
        div {
            css(buttonsContainer)
            ShareButton {
                this.link = props.link
                this.embedCode = props.embedCode
                this.slider = props.slider
                this.requestId = props.requestId
                this.isUserOwns = true
            }
            Button {
                css(newTaskButton)
                onClick = {
                    dispatch(UpdateCurrentLanguageAutoRequest(LanguageAutoState(
                        interfaceLanguage = localStorage["interface_language"] ?: ELanguage.ENGLISH.text,
                        languageToLearn = localStorage["language_to_learn"] ?: "",
                        languageLevel = localStorage["language_level"] ?: ELanguageLevel.ANY.value,
                        translateRequestToTargetLang = userSliderSettings?.translateRequestToTargetLanguage ?: false,
                        numberOfSlidesToGenerate = userSliderSettings?.numberOfSlidesToGenerate ?: 8
                    )))
                }
                Icon {
                    css(activeFilter)
                    src = "ic_magic_wand_24x24.svg"
                }
                +"Create new"
            }
        }
    }
}