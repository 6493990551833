package pages.payment.ui.PaymentCard

import antd.Button
import csstype.rem
import emotion.react.css
import entities.errorModal.store.ErrorModalState
import entities.errorModal.ui.ErrorModal
import entities.modalLoader.ModalLoaderState
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELanguage
import pages.constructor.ui.components.modalLoader.LanguageAutoModalLoader
import pages.languageAuto.getUserProfileRequest
import pages.payment.getEmailRequest
import pages.payment.getSelectedAccountRequest
import pages.payment.payRequest
import pages.payment.ui.Payment.EPaymentPerks
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.router.dom.useSearchParams
import react.useEffectOnce
import react.useState
import shared.components.description.Description
import shared.components.header.Header
import widgets.LanguageAutoContent.ui.generateButton

external interface PaymentCardProps : Props {
    var cost: Int
    var header: String
    var description: String
    var additionalInfo: String
    var discount: Int?
    var durationMonths: Int
    var promoCode: String?
    var nativeLanguage: String
    var numberOfCollaborators: Int?
}

val PaymentCard = FC<PaymentCardProps> { props ->
    val nativeLanguage = props.nativeLanguage
    val (errorState, setErrorState) = useState(ErrorModalState(open = false))
    val (loader, setLoader) = useState(ModalLoaderState(loading = false, text = null))
    val (searchParams, setSearchParams) = useSearchParams()

    val fromLanguageAuto = searchParams.get(EPaymentPerks.LANGUAGE_AUTO.param)?.toBooleanStrictOrNull()

    fun closeLoader() {
        setLoader(ModalLoaderState(loading = false, text = null))
    }

    useEffectOnce {
        val wasPaymentFailed = searchParams.get("failed")?.toBooleanStrictOrNull()
        if (wasPaymentFailed != true) {
            return@useEffectOnce
        }

        searchParams.delete("failed")
        setSearchParams(searchParams)
        setErrorState(
            errorState.copy(
                open = true,
                text = "Error handling payment"
            )
        )
    }

    LanguageAutoModalLoader {
        loading = loader.loading
        text = loader.text
        showSuccessfulLoading = loader.showSuccessfulLoading
        subText = loader.subText
    }
    ErrorModal {
        isOpen = errorState.open
        title = errorState.title
        text = errorState.text
        onClose = { setErrorState(ErrorModalState(open = false)) }
    }
    div {
        css(paymentCard(props.discount != null))
        if (props.discount != null) {
            p {
                css(discount)
                +"${props.discount}% ${cheaper(nativeLanguage)}"
            }
        }
        div {
            css(header)
            Header {
                text = props.header
                fontSize = 1.25.rem
                lineHeight = 1.625.rem
            }
            Description {
                text = props.description
                fontSize = 16.0
                lineHeight = 21.6
            }
        }
        Button {
            css(generateButton)
            onClick = {
                setLoader(ModalLoaderState(loading = true, text=connectingToPaymentSystem(nativeLanguage)))
                GlobalScope.launch {
                    val user = getUserProfileRequest()
                    if (user.data == null) {
                        closeLoader()
                        setErrorState(errorState.copy(
                            open = true,
                            text = "Error getting user"
                        ))
                        return@launch
                    }

                    val selectedAccount = getSelectedAccountRequest(user.data.userId)
                    if (selectedAccount.data == null) {
                        closeLoader()
                        setErrorState(errorState.copy(
                            open = true,
                            text = "Error getting payment info"
                        ))
                        return@launch
                    }

                    val email = getEmailRequest()
                    if (email.data == null) {
                        closeLoader()
                        setErrorState(errorState.copy(
                            open = true,
                            text = "Error getting email"
                        ))
                        return@launch
                    }

                    val response = payRequest(
                        (props.cost / props.durationMonths) * props.durationMonths,
                        selectedAccount.data.paymentAccountId,
                        user.data.userName,
                        email.data,
                        props.durationMonths,
                        props.promoCode,
                        successUrl = if (fromLanguageAuto == true)  {
                            window.location.href.replace(window.location.pathname, "/exercises")
                        } else {
                            window.location.href.replace(window.location.pathname, "/duo/content?confirm_payment=${true}")
                        },
                        numberOfCollaborators = props.numberOfCollaborators ?: 0
                    )

                    closeLoader()
                    if (response.data == null || response.data == "null") {
                        setErrorState(errorState.copy(
                            open = true,
                            text = "Error handling payment"
                        ))
                        return@launch
                    }

                    window.location.replace(response.data)
                }
            }
            +"$${props.cost / props.durationMonths} / ${monthTitle(nativeLanguage)}"
        }
        Description {
            text = props.additionalInfo
            fontSize = 16.0
            lineHeight = 21.6
        }
    }
}

private fun monthTitle(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "месяц" } else { "month" }
private fun cheaper(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "дешевле" } else { "cheaper" }
private fun connectingToPaymentSystem(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "Подключение к платежной системе" } else { "Connecting to the payment system..." }
