package pages.payment.ui.faq

data class FAQMeta(val question: String, val answer: String)

val faqsEn = listOf(
    FAQMeta(
        question = "What happens after payment? What if something goes wrong?",
        answer = """
        After payment, you will receive unlimited access to Inream as described above. If something goes wrong, we are always available at info@interactiver.online and info@inream.com. Feel free to reach out with any questions, comments, or suggestions.
    """.trimIndent()
    ),
    FAQMeta(
        question = "What language level is Inream designed for?",
        answer = """
        Join us if you're somewhere between "I've learned the alphabet" and "I can comfortably talk about the topics I need."
    """.trimIndent()
    ),
    FAQMeta(
        question = "Can I learn a language using only Inream?",
        answer = """
        No. Without speaking with native speakers, it is impossible to learn a foreign language fully. Inream is a supplement that creates gamified task sets focused on conversation topics. With regular practice, languages can be learned much more effectively.
    """.trimIndent()
    ),
    FAQMeta(
        question = "What will I learn with Inream?",
        answer = """
        Top 3 results noted by people who regularly complete our gamified tasks:

        1. Significant enrichment of vocabulary, especially with phrases;
        2. Improved listening comprehension;
        3. Learning and reinforcing new and important topics.
    """.trimIndent()
    ),
    FAQMeta(
        question = "I really like this project. How can I support it?",
        answer = """
        It would be great if you could tell your friends about the project and write a public review! If you'd like to contribute more, we’d love to hear from you at info@interactiver.online and info@inream.com.
    """.trimIndent()
    )
)

val faqsRu = listOf(
    FAQMeta(
        question = "Что произойдет после оплаты? Что делать если что-то пойдет не так",
        answer = """
            После оплаты вы получите неограниченный доступ к Inream, как написано выше. Если вдруг что-то пойдет не так - мы всегда на связи по info@interactiver.online и info@inream.com, или в телеграм vkatsman. Пишите с любыми вопросами, замечаниями и предложениями.
        """.trimIndent()
    ),
    FAQMeta(
        question = "На какой уровень языка рассчитан Inream?",
        answer = """
            Приходите, если вы находитесь в стадии между "я выучил алфавит" и "я в целом свободно говорю на нужные мне темы". 
        """.trimIndent()
    ),
    FAQMeta(
        question = "Можно ли выучить язык, пользуясь только Inream?",
        answer = """
            Нет. Без разговоров с носителями иностранные языки не выучить. Inream - это дополнение, которое создает наборы игровых заданий именно по темам разговоров. Благодаря регулярной практике с ним языки учатся в разы эффективнее.
        """.trimIndent()
    ),
    FAQMeta(
        question = "Чему я научусь с Inream?",
        answer = """
            Топ-3 результата, которые отмечают люди, регулярно решающие наши игровые задания:

            1. Значительное обогащение словарного и, особенно, фразового запаса;
            2. Улучшение понимания на слух;
            3. Изучение и закрепление новых и важных тем.
        """.trimIndent()
    ),
    FAQMeta(
        question = "Мне очень нравится проект, как я могу помочь ему?",
        answer = """
            Будет очень кстати, если вы расскажите о проекте своим знакомым и напишите публичный отзыв! Если вы хотите помочь большим - очень ждем вас, пишите на info@interactiver.online и info@inream.com, или сразу в телеграм vkatsman.
        """.trimIndent()
    )
)
