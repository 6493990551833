package pages.onboarding.emailAndSocials.ui

import antd.Button
import antd.Input
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.solvedTasks.ui.SolvedTasks
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.user.UpdateUserRequest
import org.w3c.dom.HTMLInputElement
import pages.onboarding.languageLevelChoice.*
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import pages.studentDuo.selectStudentDuoUserProfile
import react.VFC
import react.dom.events.KeyboardEvent
import react.dom.html.InputType
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.router.useNavigate
import react.useEffect
import react.useState
import shared.components.description.Description
import shared.components.header.Header
import utils.localization.Localization
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.UserProfile.SetAdditionalEmail
import widgets.UserProfile.SetSocials
import widgets.UserProfile.updateUserRequest

val EmailAndSocials = VFC {
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val userProfile = useSelector(selectStudentDuoUserProfile)
    val navigate = useNavigate()
    val dispatch = useAppDispatch()
    val (additionalEmail, setAdditionalEmail) = useState(userProfile?.additionalEmail ?: "")
    val (socials, setSocials) = useState(userProfile?.socials ?: "")

    useEffect(userProfile?.additionalEmail) {
        userProfile?.additionalEmail?.let {
            setAdditionalEmail(it)
        }
    }

    useEffect(userProfile?.socials) {
        userProfile?.socials?.let {
            setSocials(it)
        }
    }

    if (userProfile == null) {
        return@VFC
    }
    val emailToSentNotifications =
        if (userProfile.additionalEmail != "") userProfile.additionalEmail else userProfile.email


    section {
        css(page)
        SolvedTasks {}
        div {
            css(container)
            div {
                css(headerContainer)
                Header {
                    text = getHeader(nativeLanguage)
                }
                Description {
                    fontSize = 16.0
                    lineHeight = 21.6
                    +getDescription(nativeLanguage, emailToSentNotifications)
                }
            }
            div {
                css(verticalContainer)
                p {
                    css(label)
                    +getEmailLabel(nativeLanguage)
                }
                Input {
                    value = additionalEmail
                    onInput = {
                        setAdditionalEmail(it.currentTarget.value)
                    }
                    type = InputType.email
                    placeholder = getEmailPlaceholder(nativeLanguage)
                    onBlur = {
                        val oldEmail = userProfile.additionalEmail
                        dispatch(SetAdditionalEmail(additionalEmail))
                        GlobalScope.launch {
                            val saved = updateUserRequest(UpdateUserRequest(additionalEmail = additionalEmail))
                            if (saved) return@launch

                            dispatch(OpenErrorModal("Error updating additional email"))
                            dispatch(SetAdditionalEmail(oldEmail))
                        }
                    }
                    onPressEnter = { e: KeyboardEvent<HTMLInputElement> ->
                        e.currentTarget.blur()
                    }
                }
            }
            div {
                css(verticalContainer)
                p {
                    css(label)
                    +getSocialsLabel(nativeLanguage)
                }
                Input {
                    value = socials
                    onInput = {
                        setSocials(it.currentTarget.value)
                    }
                    placeholder = geSocialsPlaceholder(nativeLanguage)
                    onBlur = {
                        val oldSocials = userProfile.socials
                        dispatch(SetSocials(socials))
                        GlobalScope.launch {
                            val saved = updateUserRequest(UpdateUserRequest(socials = socials))
                            if (saved) return@launch

                            dispatch(OpenErrorModal("Error updating socials"))
                            dispatch(SetSocials(oldSocials))
                        }
                    }
                    onPressEnter = { e: KeyboardEvent<HTMLInputElement> ->
                        e.currentTarget.blur()
                    }
                }
            }
            Button {
                css(generateButton)
                onClick = {
                    navigate("/duo/content/theme")
                }
                +Localization.getContinueButtonText(nativeLanguage)
            }
            Button {
                css(newTaskButton)
                onClick = {
                    navigate(-1)
                }
                +Localization.getBackButtonText(nativeLanguage)
            }
        }
    }
}

fun getSocialsLabel(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Ваша соцсеть / телеграм"
    } else {
        "Your social network account"
    }
}

fun getEmailLabel(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Ваш основной email"
    } else {
        "Your active email"
    }
}

fun getDescription(nativeLanguage: String, email: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Сейчас прогресс и уведомления будут отправляться на $email"
    } else {
        "Progress and notifications will be sent to the $email"
    }
}

fun getHeader(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Последнее: уточните ваши контакты"
    } else {
        "The last: specify your contacts"
    }
}

fun getEmailPlaceholder(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "example@email.com"
    } else {
        "example@email.com"
    }
}

fun geSocialsPlaceholder(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Ваш никнейм"
    } else {
        "Your Nickname"
    }
}