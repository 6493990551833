package pages.googleAuth

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import jsonClient
import online.interactiver.common.user.LogInWithCodeRequest
import online.interactiver.common.user.SendCodeRequest
import utils.getToken

suspend fun updateUserChannel(channel: String): Boolean {
    return try {
        val response = jsonClient.put("api/v1/user-account/update-channel/${channel}") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}

suspend fun sendCodeRequest(request: SendCodeRequest): Boolean {
    return try {
        val response = jsonClient.post("/api/send-code") {
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}

suspend fun loginWithCodeRequest(request: LogInWithCodeRequest): Boolean {
    return try {
        val response = jsonClient.post("/api/login-with-code") {
            contentType(ContentType.Application.Json)
            setBody(request)
        }
        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}