package pages.payment.ui.reviews

data class ReviewMeta(val photoSrc: String, val name: String, val review: String)

val reviewsEn = listOf(
    ReviewMeta(
        photoSrc = "julia_review.png",
        name = "Julia, learning English",
        review = "There’s progress — I’m increasingly able to understand meme videos in English. Previously, their characters were chirping something incomprehensible, but now they’ve suddenly gained the gift of speech. This program has given me more than a year of courses, where I managed to say just a couple of sentences per class."
    ),
    ReviewMeta(
        photoSrc = "marina_review.png",
        name = "Marina",
        review = "I really like the principle of this repetition in different ways. I think it's really cool that you made it not about ducks who want to drink wine, or not about a pigeon who buys bread, but expressions that I hear every day."
    ),
    ReviewMeta(
        photoSrc = "nelli_review.png",
        name = "Nelli, learning Norwegian",
        review = "I’ve got better at understanding how to structure sentences. And I finally managed to learn a few words I couldn’t remember before. This focus on a single chosen topic is very appropriate and helpful. I have tried many programs. This one is better adapted to memorization than others."
    ),
    ReviewMeta(
        photoSrc = "ilia_review.png",
        name = "Ilia",
        review = "Classic way of language study never tell how to read the bill from water company or prescription for the medicine. Interactiver is a local buddy, who helps me understand small things around me better!"
    ),
    ReviewMeta(
        photoSrc = "evgenia_review.png",
        name = "Evgenia",
        review = """
            Very cool exercises!
            
            It's great that there are voiced pronunciations for different words. Thank you!"""
    ),
    ReviewMeta(
        photoSrc = "timur_review.png",
        name = "Timur, language teacher, @Timor_Mil",
        review = """
            That's is really amazing, I've got more than 30 sets of interactive exercises for my language course only by it's content!
            
            Viktor, Lilia, team — thank you for your work!""".trimMargin()
    ),
    ReviewMeta(
        photoSrc = "anna_review.png",
        name = "Anna",
        review = """
            Thank you for such interesting content!
            
            Before finding this project, I used to create different tasks on Quizlet for myself.""".trimIndent()
    ),
    ReviewMeta(
        photoSrc = "shura_review.png",
        name = "Shura, Hebrew teacher, @lord_barik",
        review = "I've made Hebrew language course based on tens of exercises sets generated on the platform. To craft sets of gamified exercises by my words or texts in a matter of clicks — thats really useful, saves my time and motivates students to practice! Thanks for founders Viktor and Lilia, you're building amazing tool for language teaching and learning!"
    ),
    ReviewMeta(
        photoSrc = "nelli_review.png",
        name = "Nelli, learning Norwegian",
        review = "I have tried many programs. This one is better adapted to memorization than the others. Take, for example, a translation or a sentence in Russian. It may not sound very elegant, but in terms of learning Norwegian, it makes the structure of the Norwegian sentence clearer. And that’s important. No one else uses this approach."
    )
)

val reviewsRu = listOf(
    ReviewMeta(
        photoSrc = "julia_review.png",
        name = "Юлия, учит английский",
        review = "Прогресс есть, я все чаще понимаю мемные ролики на английском. Раньше их герои чирикали что-то непонятное, а теперь вдруг обрели дар речи. Программа дала мне больше, чем год курсов, на которых удавалось произнести по паре предложений за занятие"
    ),
    ReviewMeta(
        photoSrc = "marina_review.png",
        name = "Марина, учит иврит",
        review = "Мне очень нравится принцип этого повторения на разные лады. Мне кажется это очень круто что вы сделали это не про уточек, которые хотят пить вино, или что не про голубя, который покупает хлеб, а выражения которые я слышу каждый день."
    ),
    ReviewMeta(
        photoSrc = "nelli_review.png",
        name = "Нелли, учит норвежский",
        review = "Я стала лучше разбираться, как простраивать предложения. И таки выучила несколько слов, которые раньше не получалось запомнить. Вот это кружение вокруг одной выбранной темы - очень уместно, и полезно. Я много прог перепробовала. Эта - лучше других адаптирована к запоминанию."
    ),
    ReviewMeta(
        photoSrc = "ilia_review.png",
        name = "Илья, учит иврит",
        review = "Классический способ изучения языка никогда не рассказывает, как читать местные квитанции или рецепты на лекарства. Inream — помощник, который помогает мне лучше понимать вещи вокруг меня!"
    ),
    ReviewMeta(
        photoSrc = "evgenia_review.png",
        name = "Евгения, учит иврит",
        review = """
            Очень классные упражнения!
            
            Здорово, что есть озвученные произношения разных слов.
            Спасибо!""".trimMargin()
    ),
    ReviewMeta(
        photoSrc = "timur_review.png",
        name = "Тимур, учитель иврита, @Timor_Mil",
        review = """
            Это очень круто, более 30 наборов интерактивных упражнений для моего курса именно по его материалам!
            
            Виктор, Лилия, команда — спасибо за вашу работу!""".trimMargin()
    ),
    ReviewMeta(
        photoSrc = "anna_review.png",
        name = "Анна, учит иврит",
        review = """
            Спасибо за такой интересный контент!
            
            До того, как найти этот проект, я сама создавала себе разные задания на Quizlet.""".trimIndent()
    ),
    ReviewMeta(
        photoSrc = "shura_review.png",
        name = "Shura, Hebrew teacher, @lord_barik",
        review = "Я создал курсы с десятками наборов упражнений, собранных на платформе. Создавать наборы игровых упражнений по моим словам или текстам за несколько кликов — это действительно экономит мое время и мотивирует студентов на практику! Спасибо основателям, вы создаете потрясающий инструмент для преподавания и изучения языка!"
    ),
    ReviewMeta(
        photoSrc = "nelli_review.png",
        name = "Нелли, учит норвежский",
        review = "Я много прог перепробовала. Эта - лучше других адаптирована к запоминанию. Возьмем, к примеру перевод или предложение на русском. оно звучит не слишком благозвучно, но с точки зрения изучения норвежского - именно так понятнее структура предложения на норвежском. а это важно. эту фишку никто не использует"
    )
)
