package widgets.StudentDuoSlider.ui

import app.useAppDispatch
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import pages.studentDuo.SetDuoCourses
import pages.studentDuo.getDuoCoursesRequest
import react.VFC
import react.router.useNavigate
import shared.components.ProtectedRoute.ProtectedRoute

val StudentDuoSliderFromCourse = VFC {
    val navigate = useNavigate()
    val dispatch = useAppDispatch()

    ProtectedRoute {
        toStudentDuo = true
        StudentDuoSlider {
            showBackButton = true
            onContinueClick = {
                navigate("${window.location.pathname}/words-progress")
            }
            onSolved = f@{
                GlobalScope.launch {
                    val coursesResponse = getDuoCoursesRequest()
                    if (coursesResponse.isNotEmpty()) {
                        dispatch(SetDuoCourses(coursesResponse))
                    }
                }
            }
        }
    }
}
