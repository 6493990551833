package online.interactiver.common.enums

enum class ELanguage(val text: String, val code: String, val align: String, val useSpaces: String, val icon: String, val lettersRegex: Regex, val detailString: String? = null) {
    ENGLISH("English", "en-US", "Left", "1", "\uD83C\uDDEC\uD83C\uDDE7", Regex("^[a-zA-Z\\s]*$")),
    SPANISH("Spanish", "es-ES", "Left","1", "\uD83C\uDDEA\uD83C\uDDF8", Regex("^[a-zA-ZñÑáéíóúüÁÉÍÓÚÜ\\s]*$")),
    RUSSIAN("Russian", "ru-RU", "Left","1", "\uD83C\uDDF7\uD83C\uDDFA", Regex("^[а-яА-ЯёЁ\\s]*$")),
    ARABIC("Arabic", "ar-XA", "Right","1", "\uD83C\uDDF8\uD83C\uDDE6", Regex("^[\\u0600-\\u06FF\\s]*$")),
    CHINESE("Chinese", "cmn-CN", "Left","0", "\uD83C\uDDE8\uD83C\uDDF3\uD83C\uDDF9\uD83C\uDDFC", Regex("^[\\u4E00-\\u9FFF\\s]*$")),
    FRENCH("French", "fr-FR", "Left","1", "\uD83C\uDDEB\uD83C\uDDF7", Regex("^[a-zA-ZàâçéèêëîïôûùüÿÀÂÇÉÈÊËÎÏÔÛÙÜŸ\\s]*$")),
    GERMAN("German", "de-DE", "Left","1", "\uD83C\uDDE9\uD83C\uDDEA", Regex("^[a-zA-ZäöüßÄÖÜ\\s]*$")),
    HEBREW("Hebrew", "he-IL", "Right","1", "\uD83C\uDDEE\uD83C\uDDF1", Regex("^[\\u05D0-\\u05EA\\s]*$")),
    ITALIAN("Italian", "it-IT", "Left","1", "\uD83C\uDDEE\uD83C\uDDF9", Regex("^[a-zA-ZàèéìòùÀÈÉÌÒÙ\\s]*$")),
    JAPANESE("Japanese", "ja-JP", "Left","0", "\uD83C\uDDEF\uD83C\uDDF5", Regex("^[\\u3040-\\u30FF\\u4E00-\\u9FFF\\s]*$")),
    KOREAN("Korean", "ko-KR", "Left","0", "\uD83C\uDDF0\uD83C\uDDF7", Regex("^[\\uAC00-\\uD7A3\\s]*$")),
    UKRAINIAN("Ukrainian", "uk-UA", "Left","1", "\uD83C\uDDFA\uD83C\uDDE6", Regex("^[а-яА-ЯёЁіІїЇєЄ\\s]*$")),
    AFRIKAANS("Afrikaans", "af-ZA", "Left","1", "\uD83C\uDDFF\uD83C\uDDE6", Regex("^[A-Za-zéèêëáàîïôóùúÉÈÊËÁÀÎÏÔÓÙÚ\\s]*$")),
    CATALAN("Catalan", "ca-ES", "Left","1", "\uD83C\uDDEA\uD83C\uDDF8", Regex("^[A-Za-zàçéèíïóòúüÀÇÉÈÍÏÓÒÚÜ\\s]*$")),
    CZECH("Czech", "cs-CZ", "Left","1", "\uD83C\uDDE8\uD83C\uDDFF", Regex("^[a-zA-ZáčďéěíňóřšťúůýžÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ\\s]*$")),
    BENGALI("Bengali", "bn-IN", "Left","1", "\uD83C\uDDE7\uD83C\uDDE9", Regex("^[\\u0980-\\u09FF\\s]*$")),
    BULGARIAN("Bulgarian", "bg-BG", "Left","1", "\uD83C\uDDE7\uD83C\uDDEC", Regex("^[а-яА-ЯёЁ\\s]*$")),
    DANISH("Danish", "da-DK", "Left","1", "\uD83C\uDDE9\uD83C\uDDF0", Regex("^[a-zA-ZæøåÆØÅ\\s]*$")),
    DUTCH("Dutch", "nl-NL", "Left","1", " \uD83C\uDDF3\uD83C\uDDF1", Regex("^[a-zA-ZéèëÉÈËïÏ\\s]*$")),
    FILIPINO("Filipino", "fil-PH", "Left","1", "\uD83C\uDDF5\uD83C\uDDED", Regex("^[A-Za-záéíóúñÁÉÍÓÚÑ\\s]*$")),
    FINNISH("Finnish", "fi-FI", "Left","1", "\uD83C\uDDEB\uD83C\uDDEE", Regex("^[a-zA-ZäöÄÖ\\s]*$")),
    GREEK("Greek", "el-GR", "Left","1", "\uD83C\uDDEC\uD83C\uDDF7", Regex("^[\\u0370-\\u03FF\\s]*$")),
    HINDI("Hindi", "hi-IN", "Left","1", "\uD83C\uDDEE\uD83C\uDDF3", Regex("^[\\u0900-\\u097F\\s]*$")),
    HUNGARIAN("Hungarian", "hu-HU", "Left","1", "\uD83C\uDDED\uD83C\uDDFA", Regex("^[a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ\\s]*$")),
    ICELANDIC("Icelandic", "is-IS", "Left","1", "\uD83C\uDDEE\uD83C\uDDF8", Regex("^[A-Za-zÁÐÉÍÓÚÝÞÆÖáðéíóúýþæö\\s]*$")),
    INDONESIAN("Indonesian", "id-ID", "Left","1", "\uD83C\uDDEE\uD83C\uDDE9", Regex("^[a-zA-Z\\s]*$")),
    LATVIAN("Latvian", "lv-LV", "Left","1", "\uD83C\uDDF1\uD83C\uDDFB", Regex("^[a-zA-ZāčēģīķļņšūžĀČĒĢĪĶĻŅŠŪŽ\\s]*$")),
    MALAY("Malay", "ms-MY", "Left","1", "\uD83C\uDDF2\uD83C\uDDFE", Regex("^[a-zA-Z\\s]*$")),
    NORWEGIAN("Norwegian", "nb-NO", "Left","1", "\uD83C\uDDF3\uD83C\uDDF4", Regex("^[a-zA-ZæøåÆØÅ\\s]*$")),
    POLISH("Polish", "pl-PL", "Left","1", "\uD83C\uDDF5\uD83C\uDDF1", Regex("^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\\s]*$")),
    PORTUGUESE_B("Portuguese_B", "pt-BR", "Left","1", "\uD83C\uDDE7\uD83C\uDDF7", Regex("^[a-zA-ZãõáâéêíóôúüÃÕÁÂÉÊÍÓÔÚÜ\\s]*$"), "Portuguese Brazil"),
    PORTUGUESE_P("Portuguese_P", "pt-PT", "Left","1", "\uD83C\uDDF5\uD83C\uDDF9", Regex("^[a-zA-ZãõáâéêíóôúüÃÕÁÂÉÊÍÓÔÚÜ\\s]*$"), "Portuguese Portugal"),
    ROMANIAN("Romanian", "ro-RO", "Left","1", "\uD83C\uDDF7\uD83C\uDDF4", Regex("^[a-zA-ZăâîșțĂÂÎȘȚ\\s]*$")),
    SERBIAN("Serbian", "sr-RS", "Left","1", "\uD83C\uDDF7\uD83C\uDDF8", Regex("^[а-яА-ЯёЁa-zA-ZčćžšđČĆŽŠĐ\\s]*$")),
    SLOVAK("Slovak", "sk-SK", "Left","1", "\uD83C\uDDF8\uD83C\uDDF0", Regex("^[a-zA-ZáäčďéíĺľňóôŕšťúýžÁÄČĎÉÍĹĽŇÓÔŔŠŤÚÝŽ\\s]*$")),
    SWEDISH("Swedish", "sv-SE", "Left","1", "\uD83C\uDDF8\uD83C\uDDEA", Regex("^[a-zA-ZåäöÅÄÖ\\s]*$")),
    TAMIL("Tamil", "ta-IN", "Left","1", "\uD83C\uDDF1\uD83C\uDDF0", Regex("^[\\u0B80-\\u0BFF\\s]*$")),
    THAI("Thai", "th-TH", "Left","1", "\uD83C\uDDF9\uD83C\uDDED", Regex("^[\\u0E00-\\u0E7F\\s]*$")),
    TURKISH("Turkish", "tr-TR", "Left","1", "\uD83C\uDDF9\uD83C\uDDF7", Regex("^[a-zA-ZğüşöçİĞÜŞÖÇ\\s]*$")),
    VIETNAMESE("Vietnamese", "vi-VN", "Left","1", "\uD83C\uDDFB\uD83C\uDDF3", Regex("^[\\p{L}\\s]*$")),
    UKRAINE("Ukraine", "uk-UA", "Left","1", "\uD83C\uDDFA\uD83C\uDDE6", Regex("^[а-яА-ЯёЁіІїЇєЄ\\s]*$"));

    override fun toString(): String {
        return text
    }

    fun gptName(): String {
        return detailString ?: text
    }

    fun stringContainOnlyThisLanguageLetters(string: String): Boolean {
        val result = this.lettersRegex.matches(string.filter { it.isLetter() })
        if (!result) {
            println("Error: string '$string' is not in $text")
        }
        return result
    }

    companion object {
        val icons = entries.map { it.icon }

        fun fromText(text: String?): ELanguage? {
            return ELanguage.entries.find { it.text == text }
        }
    }
}
