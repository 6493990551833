package pages.payment

import ApiResponse
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import online.interactiver.common.account.orders.OrderRequest
import online.interactiver.common.account.selectedaccount.SelectedAccount
import online.interactiver.common.payment.ProTariffPlanCosts
import utils.getToken

suspend fun checkPayRequest(): ApiResponse<Boolean> {
    return try {
        val response = jsonClient.get("/api/v1/orders/verify-last-order") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(response.bodyAsText().toBooleanStrictOrNull(), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun payRequest(
    cost: Int, paymentAccountId: Int, name: String, email: String, durationMonths: Int, promoCode: String?,
    successUrl: String, numberOfCollaborators: Int
): ApiResponse<String> {
    val requestName = "Inream Pro 1 ${if (durationMonths == 1) "month" else "year"}${if (numberOfCollaborators != 0) " together" else ""}"
    return try {
        val request = OrderRequest()
        request.name = requestName
        request.amount = cost.toDouble()
        request.clientName = name
        request.clientEmail = email
        request.paymentSystem = "AllPAY"
        request.tariffPlan = "PRO"
        request.paymentAccountId = paymentAccountId
        request.durationMonths = durationMonths.toLong()
        request.failUrl = "${window.location.href}?failed=${true}"
        request.successUrl = successUrl
        request.promoCode = promoCode
        request.numberOfCollaborators = numberOfCollaborators

        val response = jsonClient.post("/api/v1/orders") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(request)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun getSelectedAccountRequest(userId: Int): ApiResponse<SelectedAccount> {
    return try {
        val response = jsonClient.get("/api/v1/selected-account?user_id=$userId") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun getEmailRequest(): ApiResponse<String> {
    return try {
        val response = jsonClient.get("/api/v1/user-account/email") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(response.bodyAsText(), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun getDiscount(promoCode: String): Int? {
    return try {
        val response = jsonClient.get("/api/v1/promo-code?promo_code=$promoCode") {
            bearerAuth(getToken())
        }

        if (response.status.value != 200) {
            return null
        }

        response.bodyAsText().toIntOrNull()
    } catch (e: ResponseException) {
        null
    }
}

suspend fun getProTariffPlanCostsRequest(): ProTariffPlanCosts? {
    return try {
        val response = jsonClient.get("/api/v1/promo-code/pro") {
            bearerAuth(getToken())
        }

        if (response.status.value != 200) {
            return null
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (e: ResponseException) {
        null
    }
}
