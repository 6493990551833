package widgets.AdminPanel.ui

import antd.Button
import antd.Drawer
import app.useAppDispatch
import emotion.react.css
import entities.viewport.EAvailableBreakpoints
import entities.viewport.useViewportContext
import features.upgradeToProButton.UpgradeToProButton
import kotlinx.browser.localStorage
import pages.constructor.ui.components.elements.blueFilter
import pages.constructor.ui.components.elements.darkGreyFilter
import pages.languageAuto.ui.ERoute
import pages.payment.ui.Payment.EPaymentPerks
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.dom.html.ReactHTML.span
import react.redux.useSelector
import react.router.dom.Link
import react.router.dom.useSearchParams
import react.router.useLocation
import react.router.useNavigate
import react.useState
import shared.components.Icon
import utils.deleteCookieByName
import utils.types.extend
import widgets.LanguageAutoTask.ui.divider
import widgets.UserProfile.selectUserProfile

val ResponsibleAdminPanel = FC<Props> {
    val windowWidth = useViewportContext()
    val (drawerOpen, setDrawerOpen) = useState(false)
    if (windowWidth >= EAvailableBreakpoints.MOBILE.width) {
        AdminPanel {}
        return@FC
    }

    div {
        css(mobileHeaderContainer)
        Icon {
            src = "ic_inream_121x31.svg"
        }
        Button {
            css(iconButton)
            onClick = {
                setDrawerOpen(true)
            }
            Icon {
                css {
                    hover(blueFilter)
                }
                src = "ic_menu_44x44.svg"
            }
        }
    }
    Drawer {
        open = drawerOpen
        onClose = { setDrawerOpen(false)}
        placement = "right"
        closable = false
        width = 280
        AdminPanel {
            closeDrawer = { setDrawerOpen(false) }
        }
    }
}

external interface AdminPanelProps : Props {
    var closeDrawer: (() -> Unit)?
}

val AdminPanel = FC<AdminPanelProps> { props ->
    val location = useLocation()
    val windowWidth = useViewportContext()
    val userProfile = useSelector(selectUserProfile)

    val navigate = useNavigate()
    val dispatch = useAppDispatch()

    val (searchParams, setSearchParams) = useSearchParams()

    section {
        css(widget)
        div {
            css(panel)
            div {
                css(tabs)
                if (userProfile != null) {
                    Button {
                        css(profile)
                        if (userProfile.photoSrc == null) {
                            div {
                                css(imageProfile.extend(imageReplacement))
                                +userProfile.userName.split(" ").filter { it.isNotBlank() }.take(2)
                                    .joinToString("") { it.first().toString() }
                            }
                        } else {
                            img {
                                css(imageProfile)
                                src = userProfile.photoSrc
                            }
                        }
                        div {
                            css(userNameContainer)
                            p {
                                css(userName)
                                +userProfile.userName
                            }
                            p {
                                css(viewProfile)
                                +"View profile"
                            }
                        }
                        onClick = {
                            navigate(ERoute.PROFILE.path)
                            props.closeDrawer?.invoke()
                        }
                    }
                }
                Button {
                    css(tab(location.pathname == ERoute.EXERCISE.path))
                    onClick = f@{
                        if (location.pathname == ERoute.EXERCISE.path) {
                            return@f
                        }

                        navigate(ERoute.EXERCISE.path)
                        props.closeDrawer?.invoke()
                    }
                    div {
                        css(iconContainer)
                        Icon {
                            if (location.pathname == ERoute.EXERCISE.path) {
                                css(blueFilter)
                            } else {
                                css(darkGreyFilter)
                            }
                            src = "ic_plus_32x32.svg"
                        }
                    }
                    span {
                        +"Generate"
                    }
                }
                Button {
                    css(tab(location.pathname == ERoute.EXERCISES.path))
                    onClick = f@{
                        if (location.pathname == ERoute.EXERCISES.path) {
                            return@f
                        }

                        props.closeDrawer?.invoke()
                        if (location.pathname == ERoute.EXERCISE.path) {
                            navigate("${ERoute.EXERCISES.path}?$searchParams")
                        } else {
                            navigate(ERoute.EXERCISES.path)
                        }
                    }
                    div {
                        css(iconContainer)
                        Icon {
                            if (location.pathname == ERoute.EXERCISES.path) {
                                css(blueFilter)
                            } else {
                                css(darkGreyFilter)
                            }
                            src = "ic_hashtag_24x24.svg"
                        }
                    }
                    span {
                        +"My exercises"
                    }
                }
                Button {
                    css(tab(location.pathname == ERoute.SHARED_EXERCISES.path))
                    onClick = f@{
                        if (location.pathname == ERoute.SHARED_EXERCISES.path) {
                            return@f
                        }

                        props.closeDrawer?.invoke()
                        navigate(ERoute.SHARED_EXERCISES.path)
                    }
                    div {
                        css(iconContainer)
                        Icon {
                            if (location.pathname == ERoute.SHARED_EXERCISES.path) {
                                css(blueFilter)
                            } else {
                                css(darkGreyFilter)
                            }
                            src = "ic_people_24x24.svg"
                        }
                    }
                    span {
                        +"Shared with me"
                    }
                }
                Button {
                    css(tab(location.pathname == ERoute.COMMUNITY.path))
                    onClick = f@{
                        if (location.pathname == ERoute.COMMUNITY.path) {
                            return@f
                        }

                        props.closeDrawer?.invoke()
                        navigate(ERoute.COMMUNITY.path)
                    }
                    div {
                        css(iconContainer)
                        Icon {
                            if (location.pathname == ERoute.COMMUNITY.path) {
                                css(blueFilter)
                            } else {
                                css(darkGreyFilter)
                            }
                            src = "ic_4_dots_16x16.svg"
                        }
                    }
                    span {
                        +"Community"
                    }
                }
            }
            div {
                css(divider)
            }
            div {
                css(tabs)
                Button {
                    css(tab(location.pathname == ERoute.SETTINGS.path))
                    onClick = {
                        navigate(ERoute.SETTINGS.path)
                        props.closeDrawer?.invoke()
                    }
                    div {
                        css(iconContainer)
                        Icon {
                            if (location.pathname == ERoute.SETTINGS.path) {
                                css(blueFilter)
                            } else {
                                css(darkGreyFilter)
                            }
                            src = "ic_settings_21x21.svg"
                        }
                    }
                    span {
                        +"Settings"
                    }
                }
            }
            div {
                css(divider)
            }
            div {
                css(tabs)
                Button {
                    css(tab(false))
                    onClick = {
                        deleteCookieByName("access_token")
                        localStorage.removeItem("access_token")
                        navigate(to = ERoute.GOOGLE_AUTH.path)
                    }
                    +if (userProfile == null) "Log in" else "Log out"
                }
            }
        }
        if (userProfile?.tariffPlan?.uppercase() == "BASIC") {
            UpgradeToProButton {
                link = "${ERoute.PAYMENT.path}?${EPaymentPerks.LANGUAGE_AUTO.param}=${true}"
                language = ""
            }
        }
        if (windowWidth < EAvailableBreakpoints.MOBILE.width) {
            return@section
        }
        div {
            css(logoContainer)
            Icon {
                src = "ic_inream_121x31.svg"
            }
            +"In Real Motivation"
        }
    }
}
