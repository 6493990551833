package online.interactiver.common.statistics.logger

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement
import online.interactiver.common.duo.courseprogress.PlatformInfo
import online.interactiver.common.duo.wordsprogress.UpdateWordProgressRequest

@Serializable
data class AttemptsLog(
    val userId: Int?,
    val interactiveId: String?,
    val attemptUuid: String?,
    val attemptStatus: AttemptStatus,
    val userLogId: String?,
    var content: JsonElement?,
    val folderId: Int? = null,
    val uuid: String? = null,
    val platformInfo: PlatformInfo? = null,
    val channel: String? = null,
    val isPassed: Int? = null,
    val languageToLearn: String? = null,
    val interfaceLanguage: String? = null,
    val updateWordProgressRequest: List<UpdateWordProgressRequest>? = null
)
