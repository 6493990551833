package online.interactiver.common.account.members

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AddMemberRequestByEmail(
    val email: String,
    val role: String,
    @SerialName("payment_account_id")
    val paymentAccountId: Int
)