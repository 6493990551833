package pages.payment.ui.faq

import antd.Collapse
import antd.CollapsePanel
import emotion.react.css
import online.interactiver.common.enums.ELanguage
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.description.Description
import shared.components.header.Header

external interface FAQProps : Props {
    var nativeLanguage: String
}

val FAQ = FC<FAQProps> { props ->
    Header {
        text = faqHeader(props.nativeLanguage)
    }
    Collapse {
        css(collapse)
        expandIconPosition = "right"
        bordered = false

        val faqs = if (props.nativeLanguage == ELanguage.RUSSIAN.text) faqsRu else faqsEn
        faqs.forEach {
            CollapsePanel {
                css(collapsePanel)
                header = it.question
                div {
                    css(answer)
                    Description {
                        fontSize = 16.0
                        +it.answer
                    }
                }
            }
        }
    }
}

private fun faqHeader(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Частые вопросы"
} else {
    "Frequently Asked Questions"
}