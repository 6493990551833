package pages.duoSettings.duoProfile.ui.partner

import antd.Button
import antd.Input
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.account.members.AddMemberRequestByEmail
import online.interactiver.common.account.members.DeleteMemberRequest
import online.interactiver.common.account.members.MemberResponseWithEmail
import online.interactiver.common.account.members.Role
import pages.duoSettings.duoProfile.addPartnerRequest
import pages.duoSettings.duoProfile.deletePartnerRequest
import pages.duoSettings.duoProfile.ui.partners.getApplyButton
import pages.duoSettings.duoProfile.ui.partners.getPartnerInputPlaceholder
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.useState
import widgets.LanguageAutoContent.ui.generateButton

external interface PartnerProps : Props {
    var partner: MemberResponseWithEmail?
    var onChange: () -> Unit
    var paymentAccountId: Int
}

val Partner = FC<PartnerProps> {  props ->
    val (newPartnerEmail, setNewPartnerEmail) = useState(props.partner?.email ?: "")
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val dispatch = useAppDispatch()
    val partner = props.partner

    div {
        css(partnerContainer)
        Input {
            value = newPartnerEmail
            onInput = { setNewPartnerEmail(it.currentTarget.value) }
            placeholder = getPartnerInputPlaceholder(nativeLanguage)
        }
        Button {
            css(generateButton)
            onClick = {
                dispatch(StartModalLoading("Updating partner..."))
                GlobalScope.launch {
                    if (partner != null) {
                        deletePartnerRequest(DeleteMemberRequest(
                            partner.paymentAccountId, partner.userId
                        ))
                    }
                    val updated = addPartnerRequest(AddMemberRequestByEmail(
                        newPartnerEmail, Role.MEMBER.get, props.paymentAccountId
                    ))
                    dispatch(EndModalLoading())
                    if (updated == null) {
                        dispatch(OpenErrorModal("Error updating partner"))
                        return@launch
                    }

                    props.onChange()
                }
            }
            +getApplyButton(nativeLanguage)
        }
    }
}
