package pages.googleAuth.ui

import csstype.AlignSelf
import emotion.react.css
import entities.pwaInstall.PwaInstallModal
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.statistics.logger.AttemptStatus
import online.interactiver.common.statistics.logger.AttemptsLog
import org.w3c.dom.events.Event
import org.w3c.dom.get
import pages.googleAuth.ui.emailWithCode.EmailWithCode
import react.VFC
import react.dom.html.AnchorTarget
import react.dom.html.ButtonType
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.router.dom.useSearchParams
import react.router.useLocation
import react.useState
import shared.components.Icon
import shared.components.description.Description
import utils.localization.getBrowserLanguage
import utils.sendLogToServer
import widgets.StudentDuoContent.ui.EStudentDuoSearchParams

val GoogleAuth = VFC {
    val (searchParams) = useSearchParams()
    val location = useLocation()
    val nativeLanguage = (searchParams.get(EStudentDuoSearchParams.INTERFACE_LANGUAGE.value)
        ?: localStorage["duo_${EStudentDuoSearchParams.INTERFACE_LANGUAGE.value}"]) ?: getBrowserLanguage()
    console.log(nativeLanguage)
    val (pwaInstallEvent, setPwaInstallEvent) = useState<Event>()

    val fromStudentDuo = searchParams.get("duo")?.toBooleanStrictOrNull()
    val headerText = if (fromStudentDuo == true) {
        if (nativeLanguage == "Russian") {
            "Прокачай свой язык\nс адаптивными игровыми заданиями"
        } else {
            "Boost your language\nwith adaptive game-like exercises"
        }
    } else {
        "Create interactive language\nlearning exercises in one minute"
    }
    val googleOpenInOtherBrowserText = if (nativeLanguage == "Russian") {
        "Если авторизация не срабатывает, откройте Inream из другого браузера, например, Chrome или Safari"
    } else {
        "If authorisation doesn't work, try opening Inream using a different browser, such as Chrome or Safari"
    }
    if (fromStudentDuo == true) {
        PwaInstallModal {
            this.nativeLanguage = nativeLanguage
            this.pwaInstallEvent = pwaInstallEvent
            onChange = {
                setPwaInstallEvent(it)
            }
        }
    }
    main {
        css(page)
        section {
            css(auth)
            Icon {
                css(googleIcon)
                src = "ic_interactive_38x35.svg"
            }
            div {
                css(container)
                div {
                    css(authContainer)
                    h1 {
                        css(header)
                        +headerText
                    }
                    val actionUrl = "${window.location.origin}/google${if (fromStudentDuo == true) "/duo" else "/language-auto"}/login"
                    GlobalScope.launch {
                        sendLogToServer(
                            AttemptsLog(
                                null,
                                null, null,
                                AttemptStatus.USER_BEFORE_AUTH,
                                window.navigator.userAgent, null,
                                //Json.parseToJsonElement("""{"channel": "${localStorage.getItem("c") ?: searchParams.get("c")}"}"""),
                                null, null
                            )
                        )
                    }
                    if (isMetaApp()) { // form with redirection
                        form {
                            css(formCss)
                            action = actionUrl
                            method = "get"
                            button {
                                css(buttonCss)
                                type = ButtonType.submit
                                Icon {
                                    src = "ic_google_icon_24x25.svg"
                                }
                                onClick = { event ->
                                    event.preventDefault() // Предотвращаем стандартное поведение формы
                                    location.state?.let {
                                        localStorage.setItem("previous_page", it.asDynamic().from)
                                    }
                                    openInDefaultBrowser(actionUrl)
                                }
                                +"Continue with Google"
                            }
                        }
                    } else {
                        form {
                            css(formCss)
                            action = "${window.location.origin}/google${if (fromStudentDuo == true) "/duo" else "/language-auto"}/login"
                            method = "get"
                            button {
                                css(buttonCss)
                                type = ButtonType.submit
                                Icon {
                                    src = "ic_google_icon_24x25.svg"
                                }
                                onClick = {
                                    location.state?.let {
                                        localStorage.setItem("previous_page", it.asDynamic().from)
                                    }
                                }
                                +"Continue with Google"
                            }
                        }
                    }
                    if (isMetaApp()) {
                        p {
                            +googleOpenInOtherBrowserText
                        }
                    }
                    div {
                        css(orContainer)
                        div {
                            css(divider)
                        }
                        Description {
                            +"or"
                        }
                        div {
                            css(divider)
                        }
                    }
                    EmailWithCode {
                        this.nativeLanguage = nativeLanguage
                    }
                }
                div {
                    css {
                        alignSelf = AlignSelf.flexStart
                    }
                    p {
                        css(documents)
                        +"By continuing, you agree to our "
                        a {
                            css(link)
                            href = "https://inream.com/terms_of_service"
                            target = AnchorTarget._blank
                            +"Term of Use"
                        }
                        +" and "
                        a {
                            css(link)
                            target = AnchorTarget._blank
                            href = "https://inream.com/privacy_policy"
                            +"Privacy Policy."
                        }
                    }
                    p {
                        css(documents)
                        +"We use AI and cookies to provide the best site experience."
                    }
                }
            }
        }
    }
}

// Проверка на запуск в приложениях Meta (Facebook, Instagram и др.)
fun isMetaApp(): Boolean {
    val ua = window.navigator.userAgent ?: ""
    return ua.contains("FBAN", true) || ua.contains("FBAV", true) || ua.contains("Instagram", true) || ua.contains("LinkedIn", true)
}

// Открытие ссылки в браузере по умолчанию
fun openInDefaultBrowser(url: String) {
    val userAgent = window.navigator.userAgent.lowercase()
    if (userAgent.contains("android")) {
        // Android: Use Intent for Chrome
        window.location.href =
            "intent://${url.replace(Regex("^https?://"), "")}#Intent;scheme=https;package=com.android.chrome;end;"
    } else if (userAgent.contains("iphone") || userAgent.contains("ipad")) {
        // iOS: The link in the new tab
        window.open(url, "_blank")

        window.setTimeout({
            window.alert("Please, open the lang.inream.com from your browser.")
        }, 1000)
    }
}