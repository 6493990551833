package online.interactiver.common.account.members

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MemberResponseWithEmail(
    @SerialName("payment_account_id")
    val paymentAccountId: Int,
    @SerialName("user_id")
    val userId: Int,
    val role: String,
    val email: String
)

enum class Role(val get: String, val order: Int) {
    SUPER_ADMIN("SUPER_ADMIN", 1),
    OWNER("OWNER", 2),
    ADMIN("ADMIN", 3),
    MEMBER("MEMBER", 4);
}
