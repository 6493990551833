package utils.localization

import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.duo.getPredefinedTopicsByLanguageToLearnAndLevel
import online.interactiver.common.duo.predefinedTopics
import online.interactiver.common.duo.startPredefinedTopics
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.enums.ELanguageLevel
import widgets.DuoCourses.ui.getWordsProgress

class Localization {
    companion object {
        fun getLanguageSettingsButtonModalText(
            nativeLanguage: String,
            languageToLearn: String,
            languageLevel: String
        ): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Учим ${languageToLearn}, ${ELanguageLevel.fromString(languageLevel)?.toString(nativeLanguage)} уровень"
            } else {
                "Learn ${languageToLearn}, ${languageLevel} level"
            }
        }
        fun getApplyButtonText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Применить"
            } else {
                "Apply"
            }
        }
        fun getSuggestedTopicsButtonText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Варианты тем"
            } else {
                "Suggested Topics"
            }
        }
        fun getNativeLanguageLabel(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Родной язык"
            } else {
                "Native language"
            }
        }
        fun getLanguageToLearnLabel(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Язык для изучения"
            } else {
                "Language to learn"
            }
        }
        fun getNativeLanguageTitle(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "\uD83D\uDDE3️ Выберите ваш родной язык"
            } else {
                "\uD83D\uDDE3️ Choose your native language"
            }
        }
        fun getOnboardingTopicPlaceholder(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Опишите 3-5 словами тему для практики. Например: \"Заказ кофе в кафе\" или \"Глаголы в прошедшем времени\""
            } else {
                "Describe the topic you want to practice in 3-5 words. E.g. \"Ordering coffee in a café\" or \"Past tense verbs\""
            }
        }
        fun getGenerateButtonText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Запустить!"
            } else {
                "Do the magic!"
            }
        }
        fun getGenerateNetworkErrorPleaseTryAgain(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Ошибка. Пожалуйста, попробуйте еще раз. Также может помочь редактирование темы либо языка"
            } else {
                "Error. Please try again. Editing the topic or language may also help"
            }
        }
        fun getTopicPlaceholder(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Например: \"Запись к стоматологу\", \"Слова по теме аренда квартиры\", или ваш список слов или текст"
            } else {
                "e.g. \"Visit to a dentist\", \"Phrasal verbs, Advanced level\", or your list of words or text"
            }
        }
        fun getTopicTitle(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Введите тему или слова для практики на любом языке"
            } else {
                "Write a topic or words to practice in any language"
            }
        }
        fun getOtherTopicButtonText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Новая тема"
            } else {
                "New topic"
            }
        }
        fun getOtherTopicButtonTextWithLanguages(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Учить другую тему / язык"
            } else {
                "Study new topic / language"
            }
        }
        fun getNoCourseText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Нет курсов"
            } else {
                "No courses"
            }
        }
        fun getDaysLabelText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "дней"
            } else {
                "days"
            }
        }
        fun getTasksLabelText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "побед"
            } else {
                "tasks"
            }
        }
        fun getProfileAndSettingsButtonText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Профиль и настройки"
            } else {
                "Profile and settings"
            }
        }
        fun getLanguageToLearnTitle(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "\uD83D\uDCD6 Выберите язык для изучения"
            } else {
                "\uD83D\uDCD6 Choose a language you want to learn"
            }
        }
        fun getLanguageLevelTitleOnboarding(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Выберите уровень владения языком"
            } else {
                "Select your language level"
            }
        }
        fun getLanguageLevelTitle(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Уровень языка"
            } else {
                "Language level"
            }
        }
        fun getContinueButtonText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Продолжить"
            } else {
                "Continue"
            }
        }
        fun getBackButtonText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Назад"
            } else {
                "Back"
            }
        }
        fun getChooseTopicTitle(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Выберите тему для практики"
            } else {
                "What topic do you want to practice?"
            }
        }
        fun getOwnTopicButtonText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Я хочу создать задания по моей теме"
            } else {
                "I want to create exercises on my own topic"
            }
        }

        fun getStartTopics(nativeLanguage: String): List<String> {
            return if (nativeLanguage == ELanguage.RUSSIAN.text)
                startPredefinedTopics.map { it.ru }
            else
                startPredefinedTopics.map { it.en }
        }

        fun getTopics(nativeLanguage: String, languageToLearn: String, languageLevel: String): List<String> {
            return if (nativeLanguage == ELanguage.RUSSIAN.text)
                getPredefinedTopicsByLanguageToLearnAndLevel(languageToLearn, languageLevel).shuffled().take(5).map { it.ru }
            else
                getPredefinedTopicsByLanguageToLearnAndLevel(languageToLearn, languageLevel).shuffled().take(5).map { it.en }
        }

        fun getPleaseTypeTheText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Пожалуйста, введите тему"
            } else {
                "Please type the text"
            }
        }
    }
}

fun getBrowserLanguage() =
    if (window.navigator.language.startsWith("ru", ignoreCase = true)) "Russian" else ELanguage.ENGLISH.text