package pages.duoSettings.duoProfile.ui.partner

import csstype.AlignItems
import csstype.Display
import csstype.px
import utils.types.CssStyle

val partnerContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 20.px
}
