package widgets.Exercises.ui

import antd.*
import app.useAppDispatch
import csstype.*
import emotion.react.css
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import online.interactiver.common.autogeneration.*
import online.interactiver.common.enums.EAccessType
import online.interactiver.common.enums.EContentType
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.enums.ELanguageLevel
import org.w3c.dom.HTMLInputElement
import pages.course.ResetCourseAction
import pages.languageAuto.ui.ERoute
import react.*
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.redux.useSelector
import react.router.dom.useSearchParams
import react.router.useNavigate
import shared.components.Icon
import shared.components.checkbox.Checkbox
import shared.components.description.Description
import shared.components.header.Header
import utils.preloadIcons
import widgets.AdminPanel.ui.components.CreateFolderButton.CreateFolderButton
import widgets.AdminPanel.ui.components.CreateNewButton.CreateNewButton
import widgets.ExerciseSettings.selectUserSliderSettings
import widgets.Exercises.getFolderWithAncestors
import widgets.Exercises.getLanguageAutoRequest
import widgets.Exercises.getLanguageAutoRequests
import widgets.Exercises.ui.components.ActionsForMultipleExercises.ActionsForMultipleExercises
import widgets.Exercises.ui.components.Exercise.Exercise
import widgets.Exercises.ui.components.FoldersPath.FoldersPath
import widgets.Exercises.ui.components.SharePlatformLinkButton.SharePlatformLinkButton
import widgets.Exercises.ui.components.StatisticsModal.StatisticsModal
import widgets.Exercises.ui.components.UploadManuallyButton.UploadManuallyButton
import widgets.LanguageAutoSlider.ui.components.ShareModal.ShareModal
import widgets.UserProfile.selectTariffPlan

enum class EOrderedFilter(val value: String) {
    NAME("name"), TIME("time")
}

enum class EFilterParams(val value: String) {
    SEARCH_NAME("search-name"),
    LANGUAGE_TO_LEARN("language-to-learn"),
    INTERFACE_LANGUAGE("interface-language"),
    LANGUAGE_LEVEL("language-level"),
    EXERCISE_TYPE("exercise-type"),
    IS_NAME_FILTERING_DESCENDING("is-name-filtering-descending"),
    IS_TIME_FILTERING_DESCENDING("is-time-filtering-descending"),
    ORDERED_FILTER_TYPE("ordered-filter-type"),
    PARENT_FOLDER_ID("parent_folder_id")
}

external interface ExercisesProps : Props {
    var accessType: EAccessType
    var contentType: EExercisesUiContentType?
    var onExerciseClick: (LanguageAutoGeneration) -> Unit
    var onFolderClick: ((SliderAutoGenerationFolder) -> Unit)?
    var onFolderDoubleClick: ((SliderAutoGenerationFolder) -> Unit)?
    var goToFolder: ((Int?) -> Unit)?
    var selectedFolderId: Int?
    var parentFolderId: Int?
    var disabledFolderIds: List<Int>?
    var forceUpdate: Boolean?
    var onForceUpdated: (() -> Unit)?
    var userId: Int?
    var hideMenu: Boolean?
    var hideStatistics: Boolean?
    var hideAddFolder: Boolean?
    var hideAddExercise: Boolean?
    var foldersExploringMode: Boolean? // Removes many useless for folder exploring features such as selector filters and '+ New' blue button
}

val Exercises: FC<ExercisesProps> = FC { props ->
    val (exercises, setExercises) = useState(mutableListOf<LanguageAutoGeneration>())
    val userSliderSettings = useSelector(selectUserSliderSettings)
    val tariffPlan = useSelector(selectTariffPlan)

    val dispatch = useAppDispatch()
    val navigate = useNavigate()
    val (searchParams, setSearchParams) = useSearchParams()

    val exerciseSearchName = searchParams.get(EFilterParams.SEARCH_NAME.value) ?: ""
    val (exerciseSearchInputName, setExerciseSearchInputName) = useState(exerciseSearchName)
    val languageToLearn = searchParams.get(EFilterParams.LANGUAGE_TO_LEARN.value)
    val interfaceLanguage = searchParams.get(EFilterParams.INTERFACE_LANGUAGE.value)
    val languageLevel = searchParams.get(EFilterParams.LANGUAGE_LEVEL.value)
    val exerciseType = searchParams.get(EFilterParams.EXERCISE_TYPE.value)

    val isNameFilteringDescending = searchParams.get(EFilterParams.IS_NAME_FILTERING_DESCENDING.value) != false.toString()
    val isTimeFilteringDescending = searchParams.get(EFilterParams.IS_TIME_FILTERING_DESCENDING.value) != false.toString()

    val orderedFilter = EOrderedFilter.entries.find {
        it.value == searchParams.get(EFilterParams.ORDERED_FILTER_TYPE.value)
    }

    val shownExercisesCount = useMemo(exercises) {
        exercises.size.toLong()
    }

    // And this object provided by backend, so its upload initiated by changing of id and can be delayed
    val (currentFoldersPath, setCurrentFoldersPath) = useState<List<SliderAutoGenerationFolder>?>(null)

    useEffect(props.parentFolderId) {
        if (props.parentFolderId == null) {
            setCurrentFoldersPath(null)
            return@useEffect
        }

        MainScope().launch {
            val response = getFolderWithAncestors(props.parentFolderId!!)

            if (response.data != null) {
                setCurrentFoldersPath(response.data)
            } else {
                props.goToFolder?.invoke(null)
            }
        }
    }

    val rootText = useMemo(props.accessType) {
        when (props.accessType) {
            EAccessType.PRIVATE -> "My exercises"
            EAccessType.PUBLIC -> "Community"
            EAccessType.PUBLIC_OF_USER -> "Shared Exercises"
            EAccessType.SHARED -> "Shared with me"
        }
    }

    val headerText = useMemo(currentFoldersPath?.last()?.name, rootText) {
        currentFoldersPath?.last()?.name ?: rootText
    }

    val (totalExercises, setTotalExercises) = useState(0L)
    val (waitingForDataFromBackend, setWaitingForDataFromBackend) = useState(false)

    fun filterGenerations(searchName: String = exerciseSearchName) {
        setWaitingForDataFromBackend(true)
        GlobalScope.launch {
            val gens = getLanguageAutoRequests(GenerationsWithFiltersRequest(
                languageToLearn, interfaceLanguage, languageLevel, searchName, exerciseType,
                props.contentType ?: EExercisesUiContentType.ANY,
                if (orderedFilter == EOrderedFilter.NAME) isNameFilteringDescending else null,
                if (orderedFilter == EOrderedFilter.TIME) isTimeFilteringDescending else null,
                props.accessType.value, props.parentFolderId
            ), props.userId)

            setWaitingForDataFromBackend(false)
            if (gens == null) {
                return@launch
            }

            val newGens = gens.exercises
            val total = gens.totalExercisesCount

            setExercises(newGens.toMutableList())
            setTotalExercises(total)
        }
    }

    useEffect(props.forceUpdate) {
        if (props.forceUpdate == true) {
            filterGenerations()
            props.onForceUpdated?.invoke()
        }
    }

    useEffect(
        languageToLearn, interfaceLanguage, languageLevel, exerciseType,
        isNameFilteringDescending, isTimeFilteringDescending,
        props.parentFolderId
    ) {
        filterGenerations()
    }

    val (selectedRequestId, setSelectedRequestId) = useState<Int?>(null)
    val (shareModalIsOpened, setShareModalIsOpened) = useState(false)
    val (selectedRequest, setSelectedRequest) = useState<LanguageAutoState>()

    StatisticsModal {
        isOpen = selectedRequestId != null
        onClose = { setSelectedRequestId(null) }
        requestId = selectedRequestId
    }

    if (selectedRequest?.requestId != null && selectedRequest.link != null &&
        selectedRequest.embedCode != null && selectedRequest.slider != null) {
        ShareModal {
            isOpen = shareModalIsOpened
            onClose = { setShareModalIsOpened(false) }
            this.requestId = selectedRequest.requestId
            openShareModal = { setShareModalIsOpened(true) }
            this.link = selectedRequest.link
            this.embedCode = selectedRequest.embedCode
            this.slider = selectedRequest.slider
            this.isUserOwns = props.accessType == EAccessType.PRIVATE
        }
    }

    div {
        css(filtersContainer)
        div header@{
            css(headerContainer)
            div {
                css(headerWithPrevBtn)
                if (currentFoldersPath != null) {
                    div {
                        css(prevBtn)
                        Icon {
                            src = "ic_back_arrow_24x24.svg"
                        }
                        onClick = {
                            val parentOfParentFolderId = if (currentFoldersPath.size <= 1) {
                                null
                            } else {
                                currentFoldersPath[currentFoldersPath.lastIndex - 1].id
                            }

                            props.goToFolder?.invoke(parentOfParentFolderId)
                        }
                    }
                }
                Header {
                    text = headerText
                }
            }
            div {
                css(searchContainer)
                Button {
                    css(searchButton)
                    Icon {
                        src = "ic_search_24x24.svg"
                    }
                    onClick = {
                        filterGenerations()
                    }
                }
                Input {
                    css(searchInput)
                    value = exerciseSearchInputName
                    onInput = f@{
                        setExerciseSearchInputName(it.currentTarget.value)
                        if (it.currentTarget.value.isNotBlank()) {
                            return@f
                        }

                        searchParams.delete(EFilterParams.SEARCH_NAME.value)
                        setSearchParams(searchParams)
                        filterGenerations(it.currentTarget.value)
                    }
                    placeholder = "Search for exercises by link or name"
                    onPressEnter = { evt: MouseEvent<HTMLInputElement, *> ->
                        evt.currentTarget.blur()
                    }
                    onBlur = {
                        searchParams.set(EFilterParams.SEARCH_NAME.value, it.currentTarget.value)
                        setSearchParams(searchParams)
                        filterGenerations(it.currentTarget.value)
                    }
                }
            }
            if (props.foldersExploringMode != true) {
                if (props.hideAddExercise != true) {
                    if (tariffPlan?.uppercase() != "BASIC") {
                        UploadManuallyButton {
                            onUploaded = {
                                filterGenerations()
                            }
                        }
                    }
                    CreateNewButton {
                        onClick = {
                            if (currentFoldersPath == null) {
                                navigate(ERoute.EXERCISE.path)
                            } else {
                                navigate("${ERoute.EXERCISE.path}?parent_folder_id=${currentFoldersPath.last().id}")
                            }
                        }
                        label = "New"
                    }
                }
            } else {
                CreateFolderButton {
                    this.parentFolderId = currentFoldersPath?.last()?.id
                    this.onFolderCreated = { createdFolderId ->
                        filterGenerations()
                    }
                }
            }
        }
        FoldersPath {
            rootTitle = rootText
            path = currentFoldersPath
            onClickFolder = {
                props.goToFolder?.invoke(it)
            }
        }
        if (props.foldersExploringMode != true) {
            div {
                css(selectFiltersWrapper)
                div {
                    css(filters)
                    Select {
                        css(filter(168))
                        allowClear = true
                        placeholder = "Language to learn"
                        showSearch = true
                        value = languageToLearn
                        onChange = { _, option ->
                            if (option == undefined) {
                                searchParams.delete(EFilterParams.LANGUAGE_TO_LEARN.value)
                                setSearchParams(searchParams)
                            } else {
                                searchParams.set(EFilterParams.LANGUAGE_TO_LEARN.value, option.value.toString())
                                setSearchParams(searchParams)
                            }
                        }
                        ELanguage.entries.forEach { option ->
                            Option {
                                value = option.text
                                span {
                                    +option.icon
                                }
                                +option.text
                            }
                        }
                    }
                    Select {
                        css(filter(168))
                        placeholder = "Interface language"
                        showSearch = true
                        allowClear = true
                        value = interfaceLanguage
                        onChange = { _, option ->
                            if (option == undefined) {
                                searchParams.delete(EFilterParams.INTERFACE_LANGUAGE.value)
                                setSearchParams(searchParams)
                            } else {
                                searchParams.set(EFilterParams.INTERFACE_LANGUAGE.value, option.value.toString())
                                setSearchParams(searchParams)
                            }
                        }
                        ELanguage.entries.forEach { option ->
                            Option {
                                value = option.text
                                span {
                                    +option.icon
                                }
                                +option.text
                            }
                        }
                    }
                    Select {
                        css(filter(140))
                        placeholder = "Type"
                        value = EContentType.entries.firstOrNull { it.value == exerciseType }?.uiValue
                        allowClear = true
                        onChange = f@{ _, option ->
                            if (option == undefined) {
                                searchParams.delete(EFilterParams.EXERCISE_TYPE.value)
                                setSearchParams(searchParams)
                                return@f
                            }
                            EContentType.entries.find { it.uiValue == option.value.toString() }?.value?.let {
                                searchParams.set(
                                    EFilterParams.EXERCISE_TYPE.value,
                                    it
                                )
                                setSearchParams(searchParams)
                            }
                        }
                        EContentType.entries.filter { it.handleAllFormat }.forEach { option ->
                            Option {
                                value = option.uiValue
                            }
                        }
                    }
                    Select {
                        css(filter(140))
                        placeholder = "Level"
                        value = languageLevel
                        allowClear = true
                        onChange = { _, option ->
                            if (option == undefined) {
                                searchParams.delete(EFilterParams.LANGUAGE_LEVEL.value)
                                setSearchParams(searchParams)
                            } else {
                                searchParams.set(EFilterParams.LANGUAGE_LEVEL.value, option.value.toString())
                                setSearchParams(searchParams)
                            }
                        }
                        ELanguageLevel.entries.forEach { option ->
                            Option {
                                value = option.value
                            }
                        }
                    }
                }
                if (props.accessType == EAccessType.PRIVATE && props.hideAddFolder != true) {
                    if (tariffPlan?.uppercase() != "BASIC") {
                        CreateNewButton {
                            onClick = {
                                dispatch(ResetCourseAction())
                                val parentFolderId = currentFoldersPath?.lastOrNull()?.id
                                if (parentFolderId == null) {
                                    navigate(ERoute.COURSE.path)
                                } else {
                                    navigate("${ERoute.COURSE.path}?parent_folder_id=$parentFolderId")
                                }
                            }
                            label = "Course"
                            bgColor = "#5D6676"
                            bgColorHovered = "#4C5360"
                        }
                    }
                    CreateFolderButton {
                        this.parentFolderId = currentFoldersPath?.last()?.id
                        this.onFolderCreated = { createdFolderId ->
                            filterGenerations()
                        }
                    }
                }
            }
        }
    }
    data class TableDataMeta(
        val label: String,
        val descending: Boolean?,
        val onFilterChange: (() -> Unit)? = null,
    )
    div {
        css(table)
        div {
            css(tableHeader(props.parentFolderId != null))
            if (props.parentFolderId != null) {
                div {}
            }
            listOf(
                TableDataMeta(
                    label = "Name",
                    descending = isNameFilteringDescending,
                    onFilterChange = {
                        searchParams.set(EFilterParams.IS_NAME_FILTERING_DESCENDING.value, (!isNameFilteringDescending).toString())
                        searchParams.set(EFilterParams.ORDERED_FILTER_TYPE.value, EOrderedFilter.NAME.value)
                        setSearchParams(searchParams)
                    }
                ),
                TableDataMeta(
                    label = "Type",
                    descending = null
                ),
                TableDataMeta(
                    label = "Level",
                    descending = null
                ),
                TableDataMeta(
                    label = "Statistics",
                    descending = null,
                ),
                TableDataMeta(
                    label = "Time",
                    descending = isTimeFilteringDescending,
                    onFilterChange = {
                        searchParams.set(EFilterParams.IS_TIME_FILTERING_DESCENDING.value, (!isTimeFilteringDescending).toString())
                        searchParams.set(EFilterParams.ORDERED_FILTER_TYPE.value, EOrderedFilter.TIME.value)
                        setSearchParams(searchParams)
                    }
                )
            ).forEach {
                div h@{
                    css(dataHeader)
                    +it.label
                    if (it.descending == null) {
                        return@h
                    }
                    Button {
                        css(filterArrow(it.descending))
                        onClick = { it.onFilterChange?.let { it() } }
                        Icon {
                            src = "ic_arrow_down_20x20.svg"
                        }
                    }
                }
            }
            if (props.foldersExploringMode != true && props.accessType == EAccessType.PRIVATE) {
                ActionsForMultipleExercises {
                    this.chosenExercises = exercises.filter { it.isChosen }
                    updateExercises = { filterGenerations() }
                }
                Checkbox {
                    checked = exercises.isNotEmpty() && exercises.all { it.isChosen }
                    onChange = { checked ->
                        val newExercises = exercises.map { it.copy(isChosen = checked) }.toMutableList()
                        setExercises(newExercises)
                    }
                    id = "choose_all"
                }
            }
        }
        if (!waitingForDataFromBackend) {
            div {
                css(rows)
                exercises.forEach {
                    Exercise {
                        disabled = it.isFolder() && props.disabledFolderIds?.contains(it.requestId) == true
                        selected = it.isFolder() && it.requestId == props.selectedFolderId
                        request = it
                        onOpenStatistics = { setSelectedRequestId(it.requestId) }
                        openShareModal = { setShareModalIsOpened(true) }
                        this.setSelectedRequest = { setSelectedRequest(it) }
                        updateExercises = { filterGenerations() }
                        onExerciseClick = {
                            if (it.isManuallyUploaded()) {
                                dispatch(StartModalLoading("Getting content"))
                                MainScope().launch {
                                    val request = getLanguageAutoRequest(it.requestId, false)

                                    dispatch(EndModalLoading())
                                    if (request == null) {
                                        return@launch
                                    }

                                    setSelectedRequest(request)
                                    setShareModalIsOpened(true)
                                }
                            }
                            else if (it.isFolder()) {
                                props.onFolderClick?.invoke(it.asFolder())
                            } else {
                                props.onExerciseClick(it)
                            }
                        }
                        onExerciseDoubleClick = {
                            if (it.isFolder()) {
                                props.onFolderDoubleClick?.invoke(it.asFolder())
                            }
                        }
                        hideMenu = props.hideMenu == true || props.accessType.hideMenu(it.isUserOwns)
                        hideStatistics = props.hideStatistics
                        accessType = props.accessType
                        onChooseRequest = {
                            val newExercises = exercises.map { exercise ->
                                if (exercise != it) {
                                    exercise
                                } else {
                                    exercise.copy(isChosen = !exercise.isChosen)
                                }
                            }.toMutableList()
                            setExercises(newExercises)
                        }
                        foldersExploringMode = props.foldersExploringMode
                    }
                }
            }
        }
    }
    if (exercises.isNotEmpty() && !waitingForDataFromBackend) {
        div {
            css(showMoreContainer)
            SharePlatformLinkButton {
                parentFolderId = props.parentFolderId
            }
            div {
                css(showingCount)
                +"Showing $shownExercisesCount/$totalExercises"
            }
        }
        if (shownExercisesCount < totalExercises) {
            Button {
                +"Show more"
                onClick = {
                    setWaitingForDataFromBackend(true)
                    GlobalScope.launch {
                        val gens = getLanguageAutoRequests(
                            GenerationsWithFiltersRequest(
                                languageToLearn, interfaceLanguage, languageLevel, exerciseSearchName, exerciseType,
                                props.contentType ?: EExercisesUiContentType.ANY,
                                if (orderedFilter == EOrderedFilter.NAME) isNameFilteringDescending else null,
                                if (orderedFilter == EOrderedFilter.TIME) isTimeFilteringDescending else null,
                                props.accessType.value, props.parentFolderId,
                                offsetCount = shownExercisesCount
                            ), props.userId
                        )

                        setWaitingForDataFromBackend(false)
                        if (gens == null) {
                            return@launch
                        }

                        val newExercises = exercises.toMutableList()

                        val newGens = gens.exercises
                        val total = gens.totalExercisesCount

                        newExercises.addAll(newGens)
                        setExercises(newExercises)
                        setTotalExercises(total)
                    }
                }
            }
        }
    }
    if (waitingForDataFromBackend) {
        div {
            css(noExercisesContainer)
            Spin {
                size = "large"
            }
        }
    }
    if (exercises.isEmpty() && !waitingForDataFromBackend) {
        div {
            css(noExercisesContainer)
            div {
                css(noExercises)
                div {
                    css(textContainer)
                    Header {
                        fontWeight = integer(500)
                        fontSize = 18.0.px
                        lineHeight = 24.3.px
                        text = "No materials found"
                    }
                    Description {
                        fontSize = 14.0
                        lineHeight = 18.9
                        +"Try changing or cleaning your filters."
                    }
                }
                if (props.foldersExploringMode != true && props.hideAddExercise != true) {
                    CreateNewButton {
                        onClick = {
                            val parentFolderId = currentFoldersPath?.lastOrNull()?.id
                            if (parentFolderId == null) {
                                navigate(ERoute.EXERCISE.path)
                            } else {
                                navigate("${ERoute.EXERCISE.path}?parent_folder_id=$parentFolderId")
                            }
                        }
                        label = "Create new"
                    }
                }
            }
        }
    }
}
