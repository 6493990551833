package pages.duoSettings.duoProfile.ui.partner

import csstype.AlignItems
import csstype.Display
import csstype.px
import utils.types.CssStyle

val partnerContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 20.px
}

val input: CssStyle = {
    width = 250.px
}

val button: CssStyle = {
    width = 150.px
    height = 40.px
}