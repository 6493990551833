package online.interactiver.common.duo.wordsprogress

import kotlinx.serialization.Serializable

@Serializable
data class UpdateWordProgressRequest(
    val word: String,
    val definition: String,
    val phrase: String?,
    val isCorrect: Boolean?,
    val isCorrectInput: Boolean?,
    val languageToLearn: String?,
    val interfaceLanguage: String?,
    val phraseDefinition: String?
)
