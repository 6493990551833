package entities.studentDuoPromocodePopup.ui

import features.PromoCodePopup.PromoCodePopup
import kotlinx.browser.window
import pages.studentDuo.selectIsBasicTariffPlan
import pages.studentDuo.selectStudentDuoCourses
import pages.studentDuo.selectStudentDuoNativeLanguage
import react.FC
import react.Props
import react.redux.useSelector

val StudentDuoPromoCodePopup = FC<Props> {props ->
    val nativeLanguage = useSelector(selectStudentDuoNativeLanguage)
    val courses = useSelector(selectStudentDuoCourses)
    val isBasicTariffPlan = useSelector(selectIsBasicTariffPlan)

    val isSettingsPage = window.location.pathname == "/duo/settings"
    val isFitCourses = (courses.size == 1 && courses.first().numberOfSolvedExercises > 0) || courses.size > 1

    PromoCodePopup {
        open = isBasicTariffPlan && courses.size > 2 // discount option (isSettingsPage || isFitCourses)
        this.nativeLanguage = nativeLanguage
    }
}
