import app.App
import kotlinext.js.require
import kotlinext.js.requireAll
import kotlinx.browser.document
import kotlinx.browser.window
import react.create
import react.dom.client.createRoot
import reactga.ReactGA

fun main() {
    ReactGA.initialize("G-D5Y6H2632K")
    require("react-image-crop/dist/ReactCrop.css")
    requireAll(require.context(".", true, js("/\\.css$/")))

    val container = document.getElementById("root") ?: error("Couldn't find root container!")
    createRoot(container).render(App.create())

    window.addEventListener("load", {
        window.navigator.serviceWorker.register(
            "/static/service-worker.js"
        ).then {
            console.log("SW registered", it)
        }.catch {
            console.error("Sw registration failed", it)
        }
    })
}