package pages.payment.ui.reviews

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    width = 100.pct
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
}

val review: CssStyle = {
    backgroundColor = Color("#f2f4f7")
    display = important(Display.flex)
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 15.px
    borderRadius = 18.px
    boxSizing = BoxSizing.borderBox
    padding = Padding(10.px, 20.px)
    width = important(100.pct)
}

val carouselContainer: CssStyle = {
    width = 70.pct
}

val header: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 20.px
    width = 100.pct
}

val name: CssStyle = {
    margin = 0.px
    fontWeight = integer(600)
    fontSize = 18.px
}

val text: CssStyle = {
    margin = 0.px
    padding = 0.px
    fontWeight = FontWeight.normal
    fontSize = 15.px
}

val image: CssStyle = {
    width = 46.px
}

val arrowButton: CssStyle = {
    marginTop = 100.px
}