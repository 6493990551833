package widgets.ExerciseSettings.components.SettingsContainer

import emotion.react.css
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import shared.components.description.Description

external interface SettingsContainerProps : PropsWithChildren {
    var header: String
}

val SettingsContainer = FC<SettingsContainerProps> {
    div {
        css(settingsContainer)
        Description {
            fontSize = 12.0
            lineHeight = 16.2
            +it.header
        }
        +it.children
    }
}