package entities.pwaInstall

import csstype.*
import utils.types.CssStyle

val modal: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 20.px
    alignItems = AlignItems.center
    padding = 10.px
    textAlign = TextAlign.center
}

val iosContent: CssStyle = {
    display = Display.flex
    flexWrap = FlexWrap.wrap
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}
