package online.interactiver.common.account.members

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DeleteMemberRequest(
    @SerialName("payment_account_id")
    val paymentAccountId: Int,
    @SerialName("user_id")
    val userId: Int
)