package pages.duoSettings.duoProfile

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.account.members.AddMemberRequestByEmail
import online.interactiver.common.account.members.DeleteMemberRequest
import online.interactiver.common.account.members.MemberResponseWithEmail
import utils.getToken

suspend fun addPartnerRequest(addMemberRequestByEmail: AddMemberRequestByEmail): MemberResponseWithEmail? {
    return try {
        val response = jsonClient.post("/api/v1/members") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(addMemberRequestByEmail)
        }

        if (response.status.value == 200) {
            return null
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (e: ResponseException) {
        return null
    }
}

suspend fun deletePartnerRequest(deleteMemberRequest: DeleteMemberRequest): Boolean {
    return try {
        val response = jsonClient.delete("/api/v1/members") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(deleteMemberRequest)
        }

        response.status.value == 200
    } catch (e: ResponseException) {
        return false
    }
}

suspend fun getPartnersRequest(paymentAccountId: Int): List<MemberResponseWithEmail> {
    return try {
        val response = jsonClient.get("/api/v1/members?payment_account_id?$paymentAccountId") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return emptyList()
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (e: ResponseException) {
        return emptyList()
    }
}
