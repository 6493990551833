package entities.pwaInstall

import emotion.react.css
import online.interactiver.common.enums.ELanguage
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.Icon
import shared.components.description.Description

external interface IosContentProps : Props {
    var nativeLanguage: String
}

val IosContent = FC<IosContentProps> { props ->
    Description {
        fontSize = 16.0
        lineHeight = 21.6
        div {
            css(iosContent)
            +getFirstInstallText(props.nativeLanguage)
            Icon {
                src = "ic_share_ios_24x24.svg"
            }
            +getSecondInstallText(props.nativeLanguage)
            +getThirdInstallText(props.nativeLanguage)
        }
    }
}

private fun getFirstInstallText(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Для установки приложения нажмите на "
} else {
    "To install your Web Application tap "
}

private fun getSecondInstallText(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    " и дальше \"На экран домой\""
} else {
    " and then \"Add to homescreen\""
}

private fun getThirdInstallText(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    " (инструкция для Сафари) "
} else {
    " (instruction for Safari) "
}
