package pages.duoSettings.duoProfile.ui.partners

import Modal
import antd.Button
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.account.members.MemberResponseWithEmail
import online.interactiver.common.enums.ELanguage
import pages.duoSettings.duoProfile.getPartnersRequest
import pages.duoSettings.duoProfile.ui.partner.Partner
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import pages.studentDuo.selectStudentDuoUserProfile
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.useEffectOnce
import react.useState
import shared.components.loader.Loader
import widgets.LanguageAutoContent.ui.generateButton

val PartnersModalWithButton = FC<Props> {
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)
    val userProfile = useSelector(selectStudentDuoUserProfile)
    val (isOpen, setIsOpen) = useState(false)
    val (partners, setPartners) = useState<List<MemberResponseWithEmail>>()

    useEffectOnce {
        if (userProfile == null) {
            return@useEffectOnce
        }

        GlobalScope.launch {
            val partnersResponse = getPartnersRequest(userProfile.paymentAccountId).filter { it.userId != userProfile.userId }
            setPartners(partnersResponse)
        }
    }

    if (userProfile == null) {
        return@FC
    }

    Button {
        css(generateButton)
        onClick = {
            setIsOpen(true)
        }
        +getPartnersButton(nativeLanguage)
    }

    Modal {
        open = isOpen
        onCancel = { setIsOpen(false) }
        footer = null

        if (partners == null) {
            Loader {}
            return@Modal
        }
        div {
            css(container)
            for (i in 0 until userProfile.numberOfCollaborators) {
                Partner {
                    partner = partners.getOrNull(i)
                    onChange = f@{
                        GlobalScope.launch {
                            val partnersResponse =
                                getPartnersRequest(userProfile.paymentAccountId).filter { it.userId != userProfile.userId }
                            setPartners(partnersResponse)
                        }
                    }
                    paymentAccountId = userProfile.paymentAccountId
                }
            }
        }
    }
}

fun getPartnersButton(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Партнеры по обучению"
} else {
    "Study partners"
}

fun getPartnerInputPlaceholder(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Введите email партнёра"
} else {
    "Enter partner's email"
}

fun getApplyButton(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Применить"
} else {
    "Apply"
}