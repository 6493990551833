package utils

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import jsonClient
import utils.getToken
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import online.interactiver.common.statistics.logger.AttemptsLog

suspend fun sendLogToServer(data: AttemptsLog): Boolean {
    return try {
        val response = jsonClient.post("/attempts_log") {
            contentType(ContentType.Application.Json)
            setBody(data)
        }

        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}