package features.upgradeToProButton

import emotion.react.css
import online.interactiver.common.enums.ELanguage
import pages.languageAuto.ui.ERoute
import pages.payment.ui.Payment.EPaymentPerks
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.router.dom.Link
import widgets.AdminPanel.ui.upgradeContainer
import widgets.AdminPanel.ui.upgradeLink
import widgets.AdminPanel.ui.upgradeText

external interface UpgradeToProButtonProps : Props {
    var link: String
    var language: String
}

val UpgradeToProButton = FC<UpgradeToProButtonProps> { props ->
    div {
        css(upgradeContainer)
        p {
            css(upgradeText)
            +if (props.language == ELanguage.RUSSIAN.text) "BASIC тариф" else "BASIC tariff"
        }
        Link {
            css(upgradeLink)
            to = props.link
            +if (props.language == ELanguage.RUSSIAN.text) "Перейти на PRO!" else "Upgrade to PRO!"
        }
    }
}