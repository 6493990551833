package pages.payment.ui.faq

import csstype.*
import utils.types.CssStyle

val collapse: CssStyle = {
    width = 100.pct
    backgroundColor = Color("white")
}

val collapsePanel: CssStyle = {
    fontWeight = integer(600)
    fontSize = 18.px
}

val answer: CssStyle = {
    padding = Padding(10.px, 20.px)
    whiteSpace = WhiteSpace.breakSpaces
}
