package entities.pwaInstall

import antd.Button
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.statistics.logger.AttemptStatus
import online.interactiver.common.statistics.logger.AttemptsLog
import org.w3c.dom.events.Event
import pages.duoSettings.ui.getInstallPwa
import react.FC
import react.Props
import shared.components.description.Description
import utils.sendLogToServer
import widgets.LanguageAutoContent.ui.generateButton
import kotlin.js.Promise

@Serializable
data class PwaInstallEvent(val outcome: String, val platform: String)

external interface ChromeBasedContentProps : Props {
    var nativeLanguage: String
    var pwaInstallEvent: Event?
    var onChange: (Event?) -> Unit
}

val ChromeBasedContent = FC<ChromeBasedContentProps> { props ->
    Description {
        fontSize = 16.0
        lineHeight = 21.6
        +getInstallText(props.nativeLanguage)
    }
    Button {
        css(generateButton)
        onClick = {
            (props.pwaInstallEvent.asDynamic().prompt() as Promise<PwaInstallEvent>).then {
                props.onChange(null)
                console.log(it)
                if (it.outcome != "accepted") return@then

                GlobalScope.launch {
                    sendLogToServer(
                        AttemptsLog(
                            null,
                            null, null,
                            AttemptStatus.INSTALL_PWA,
                            window.navigator.userAgent, null
                        )
                    )
                }
            }.catch {
                console.error(it)
            }
        }
        +getInstallPwa(props.nativeLanguage)
    }
}

private fun getInstallText(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "Установите приложение сейчас или позже в настройках"
} else {
    "Install Web Application now or later in settings"
}
