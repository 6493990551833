package pages.payment.ui.reviews

import antd.Button
import antd.Carousel
import antd.CarouselRef
import csstype.deg
import csstype.px
import csstype.rotate
import emotion.react.css
import online.interactiver.common.enums.ELanguage
import pages.constructor.ui.components.elements.grayFilter
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.useRef
import shared.components.Icon
import shared.components.header.Header
import utils.types.extend
import widgets.AdminPanel.ui.iconButton

external interface ReviewProps : Props {
    var nativeLanguage: String
}

val Reviews = FC<ReviewProps> { props ->
    val reviews = if (props.nativeLanguage == ELanguage.RUSSIAN.text) reviewsRu else reviewsEn
    val carouselRef = useRef<CarouselRef>()
    Header {
        text = reviewsHeader(props.nativeLanguage)
    }

    div {
        css(container)
        Button {
            css(iconButton.extend(arrowButton))
            onClick = {
                carouselRef.current?.prev?.invoke()
            }
            Icon {
                css(grayFilter.extend {
                    width = 32.px
                    height = 32.px
                })
                src = "ic_arrow_left_24x24.svg"
            }
        }
        div {
            css(carouselContainer)
            Carousel {
                dots = false
                ref = carouselRef
                adaptiveHeight = true

                reviews.forEach {
                    div {
                        css(review)
                        div {
                            css(header)
                            img {
                                css(image)
                                src = kotlinext.js.require("./images/${it.photoSrc}").default
                            }
                            p {
                                css(name)
                                +it.name
                            }
                        }
                        p {
                            css(text)
                            +it.review
                        }
                    }
                }
            }
        }
        Button {
            css(iconButton.extend(arrowButton))
            onClick = {
                carouselRef.current?.next?.invoke()
            }
            Icon {
                css(grayFilter.extend {
                    transform = rotate(180.deg)
                    width = 32.px
                    height = 32.px
                })
                src = "ic_arrow_left_24x24.svg"
            }
        }
    }
}

fun reviewsHeader(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) "Отзывы" else "Reviews"