package pages.duoSettings.ui.installButtonWithModal

import Modal
import antd.Button
import csstype.TextAlign
import csstype.px
import emotion.react.css
import entities.pwaInstall.IosContent
import kotlinx.js.jso
import pages.duoSettings.ui.getInstallPwa
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import react.FC
import react.Props
import react.redux.useSelector
import react.useState
import widgets.LanguageAutoContent.ui.generateButton

val InstallButtonWithModal = FC<Props> {
    val (isOpen, setIsOpen) = useState(false)
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)

    Button {
        css(generateButton)
        onClick = {
            setIsOpen(true)
        }
        +getInstallPwa(nativeLanguage)
    }

    Modal {
        open = isOpen
        onCancel = {
            setIsOpen(false)
        }
        style = jso {
            maxWidth = 605.px
        }
        width = "80vw"
        footer = null
        bodyStyle = jso {
            padding = 10.px
            textAlign = TextAlign.center
        }

        IosContent {
            this.nativeLanguage = nativeLanguage
        }
    }
}