package pages.wordsProgress.ui

import antd.Button
import app.useAppDispatch
import csstype.None
import csstype.px
import emotion.react.css
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.duo.wordsprogress.WordProgressCommon
import online.interactiver.common.enums.ELanguage
import pages.studentDuo.SetStudentDuoTextOrTopic
import pages.studentDuo.selectRequiredStudentDuoNativeLanguage
import pages.wordsProgress.getWordsProgressForSliderRequest
import pages.wordsProgress.ui.WordProgress.WordProgressComponent
import react.FC
import react.VFC
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.router.useNavigate
import react.router.useParams
import react.useEffectOnce
import react.useState
import shared.components.ProtectedRoute.ProtectedRoute
import shared.components.description.Description
import shared.components.header.Header
import shared.components.loader.Loader
import utils.localization.Localization
import utils.types.extend
import widgets.DuoCourses.ui.getWordsProgress
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoContent.ui.EStudentDuoSearchParams
import widgets.StudentDuoLayout.StudentDuoLayout

val WordsProgress = VFC {
    val navigate = useNavigate()
    val pathParams = useParams()
    val dispatch = useAppDispatch()
    val folderId = pathParams["folder_id"]?.toIntOrNull()
    val sliderUuid = pathParams["request_uuid"]
    val (wordsProgress, setWordsProgress) = useState<List<WordProgressCommon>>()
    val nativeLanguage = useSelector(selectRequiredStudentDuoNativeLanguage)

    useEffectOnce {
        if (sliderUuid == null) {
            navigate("/duo/content")
            return@useEffectOnce
        }

        dispatch(StartModalLoading("Loading words"))
        GlobalScope.launch {
            val wordsProgressResponse = getWordsProgressForSliderRequest(sliderUuid)
            dispatch(EndModalLoading())
            if (wordsProgressResponse.isEmpty()) {
                navigate("/duo/folder/$folderId")
                return@launch
            }

            wordsProgressResponse.firstOrNull()?.let {
                localStorage.setItem("duo_${EStudentDuoSearchParams.LANGUAGE_TO_LEARN.value}", it.languageToLearn)
                localStorage.setItem("duo_${EStudentDuoSearchParams.INTERFACE_LANGUAGE.value}", it.interfaceLanguage)
            }

            setWordsProgress(wordsProgressResponse.shuffled().take(7))
        }
    }

    ProtectedRoute {
        StudentDuoLayout {
            buttons = FC {
                val markedWords = wordsProgress?.filter { it.markedToPractice }
                Button {
                    css(newTaskButton.extend {
                        borderTopLeftRadius = 0.px
                        borderTopRightRadius = 0.px
                        borderBottomRightRadius = 0.px
                    })
                    disabled = markedWords.isNullOrEmpty()
                    onClick = {
                        if (markedWords?.isNotEmpty() == true) {
                            GlobalScope.launch {
                                val wordsProgressString = getWordsProgress(20 - markedWords.size, folderId).filter { w ->
                                    markedWords.all {it.word != w}
                                }.joinToString(", ")
                                dispatch(SetStudentDuoTextOrTopic(
                                    "${markedWords.joinToString(", ") { it.word }}${if (wordsProgressString.isBlank()) "" else ", $wordsProgressString"}"
                                ))
                                navigate("/duo/content")
                            }
                        } else {

                        }
                    }
                    +getLearnMarkedWords(nativeLanguage)
                }
                Button {
                    css(newTaskButton.extend {
                        textDecoration = None.none
                        borderRadius = 0.px
                    })
                    onClick = {
                        navigate("/duo/content")
                    }
                    +Localization.getOtherTopicButtonText(nativeLanguage)
                }
                Button {
                    css(generateButton.extend {
                        textDecoration = None.none
                        borderTopLeftRadius = 0.px
                        borderTopRightRadius = 0.px
                        borderBottomLeftRadius = 0.px
                    })
                    onClick = {
                        navigate("/duo/folder/$folderId")
                    }
                    +Localization.getContinueButtonText(nativeLanguage)
                }
            }
            div {
                css(container)
                div {
                    css(headerContainer)
                    Header {
                        text = getCheckSliderHeader(nativeLanguage)
                    }
                    Description {
                        fontSize = 16.0
                        lineHeight = 21.6
                        +getCheckSliderDescription(nativeLanguage)
                    }
                }
                if (wordsProgress == null) {
                    Loader {}
                    return@div
                }
                wordsProgress.forEach { word ->
                    WordProgressComponent {
                        wordProgress = word
                        onChange = {
                            setWordsProgress {
                                wordsProgress.map {
                                    if (it != word) return@map it

                                    it.copy(markedToPractice = !it.markedToPractice)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun getCheckSliderHeader(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Какие слова ты не помнишь? \uD83E\uDDD0"
    } else {
        "What words don't you remember? \uD83E\uDDD0"
    }
}

fun getCheckSliderDescription(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Жми на слово чтобы проверить и добавить в словарь"
    } else {
        "Tap on the card to check and add to your vocabulary"
    }
}

fun getLearnMarkedWords(nativeLanguage: String): String {
    return if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Учить слова"
    } else {
        "Learn words"
    }
}